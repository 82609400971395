.container {
    position: relative;
    box-sizing: border-box;
    display: flex;
}

.viewGallery {
    /*width: 100px;*/
    width: fit-content;
    max-width: 114px;
    height: 50px;
    overflow: hidden;
    border-radius: 6px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    transform: translateZ(0);
}

.viewGalleryOpened {
    max-width: 100%;
    overflow-x: auto;
    padding-right: 15px;
}

.viewGallery > * {
    width: 50px;
    min-width: 50px;
}

.viewMosaic {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    /*display: grid;*/
    /*grid-template-columns: repeat(2, 1fr);*/
    /*grid-auto-rows: 1fr;*/
    /*justify-items: stretch;*/

    /*height: 70%;*/
    max-height: 70%;
    /*overflow: auto;*/
}

.viewMosaic > * {
    width: 50%;
    min-width: 50%;
}

.viewMosaicWithOtherViewers {
    height: 100%;
    max-height: 100%;
    align-content: flex-start;
}

.viewMosaicWithOtherViewers > *:nth-child(1) {
    width: 100%;
    min-width: 100%;
    height: 50%;
    max-height: 50%;
}

.viewMosaicWithOtherViewers video {
    object-fit: contain;
}

.viewMosaicWithOtherViewers1 > *:not(:first-child) {
    height: 50%;
}

.viewMosaicWithOtherViewers2or3 > *:not(:first-child) {
    height: 25%;
}

.viewMosaicWithOtherViewers4or5 > *:not(:first-child) {
    height: 16.8%;
}

.viewMosaicWithOtherViewersMore5 {
    overflow-y: auto;
}

.viewMosaicWithOtherViewersMore5 > *:not(:first-child) {
    height: 16.8%;
}

.viewReport {
    width: 100%;
    height: 100%;
    max-height: 100%;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
}

.viewReport > * {
    height: 50%;
    max-height: 50%;
}

.openListBtn {
    position: absolute;
    right: 0;
    margin-left: auto;

    background: #393F61;
    height: 100%;
    min-width: 15px;
    width: 15px;

    padding: 0;
    border: none;
}

.openListBtn svg {
    margin: auto;
}