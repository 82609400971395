.blockContainer {
    position: relative;
    margin-top: auto;
    box-sizing: border-box;
    background: #FFFFFF;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);
    border-radius: 6px;
    width: 290px;
}

.title {
    margin: 0;
    color: #393F61;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
}

.closeButton {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;

    position: absolute;
    top: 10px;
    right: 12px;
    height: 12px;
    cursor: pointer;
}

.list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}

.item {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    flex-shrink: 0;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #393F61;
}

.item svg {
    margin-right: 5px;
    width: 32px;
    height: 24px;
}

.buttonOpenBlock {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;

    cursor: pointer;
    position: relative;
    margin-top: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.buttonOpenBlock svg {
    width: 32px;
    height: 24px;
    margin-right: 10px;
}

.buttonOpenBlock span {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #4DA3F7;
    margin: 0;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.3s ease;
}

.buttonOpenBlock:before, .buttonOpenBlock:after {
    position: absolute;
    content: '';
    right: 95px;
    top: 5px;
    width: 16px;
    height: 16px;
    transition: opacity 0.3s ease;
}

.buttonOpenBlock:before {
    background: url("../../img/question.svg") center no-repeat;
}

.buttonOpenBlock:after {
    opacity: 0;
    background: url("../../img/questionFill.svg") center no-repeat;
}

.buttonOpenBlock:hover span {
    border-bottom: 1px solid #4DA3F7;
}

.buttonOpenBlock:hover:before {
    opacity: 0;
}

.buttonOpenBlock:hover:after {
    opacity: 1;
}