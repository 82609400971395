.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
}

.buttonWrap {
  flex: 0 0 20%;
}

.button {
  position: relative;
  vertical-align: top;
  background: none;
  border: none;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: var(--primary);
  cursor: pointer;

  &:active, &:focus {
    border-color: #4da3f7;
    outline: none;
  }

  &:active {
    border-radius: 50%;
    display: block;
    background: var(--primary);
    color: #fff;
  }
}
