.icon {
    width: 26px;
    height: 26px;
}

.button {
    cursor: pointer;
    padding: 0;
    background: none;
    outline: none;
    padding: 0;
    color: currentColor;
    border: none;
    font-size: inherit;
}