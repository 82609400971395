.container {
    padding: 70px 0 50px;
    overflow-x: hidden;

    max-width: 738px;
    margin: auto;
}

.h2 {
    font-family: "Circe";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    margin-bottom: 24px;
}

.subtitle {
    text-align: center;
    font-family: "Circe";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto 30px;
    max-width: 420px;
}

@media only screen and (max-width: 1024px) {
    .container {
        padding: 25px 0;
        overflow-x: initial;
    }
}
