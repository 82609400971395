.wrap {
  overflow: hidden;
  max-width: 100%;
  position: relative;
  height: 49px;
  min-width: 185px;
  margin: 0;
  padding-left: 2px;
  display: flex;
  background: #fff;
}

.tooltip {
  border-radius: 6px;
  padding: 15px 10px!important;
  opacity: 1!important;
  /*box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);*/
}

.wrapModal {
  height: 90px;
}

.wrapModal .item {
  height: 90px;
  width: 57px;
  margin: 0 1px;
}

.modalHeight {
  height: 80px;
}

.slider {
  overflow: hidden;
  max-width: 100%;
  position: relative;
}

.list {
  list-style: none;
  padding: 0;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  user-select: none;
  cursor: pointer;
  margin: 0;
}

.item {
  width: 30px;
  height: 45px;
  border: 2px solid transparent;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
  margin: 0 2px;
}

.itemClean {
  width: 30px;
  border: 2px solid var(--primary);
}

.itemClean:before {
  position: absolute;
  content: '';
  height: 110px;
  width: 2px;
  right: 28px;
  top: -8px;
  background-color: var(--primary);
  transform: rotate(31deg);
}

.itemCleanModal {
  position: relative;
  width: 56px;
  height: 88px;
  margin: 0 2px;
  border: 2px solid var(--primary);
}

.itemCleanModal:before {
  position: absolute;
  content: '';
  height: 110px;
  width: 2px;
  right: 28px;
  top: -8px;
  background-color: var(--primary);
  transform: rotate(31deg);
}


/*.itemCleanModal svg {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

.item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.visited:after {
  background: linear-gradient(
          0deg,
          rgba(77, 163, 247, 0.5),
          rgba(77, 163, 247, 0.5)
  );
}

/*.visited:after {*/
/*  background: #DBEDFD;*/
/*  opacity: .5;*/
/*}*/

.selected {
  border-color: var(--primary);
  border: 2px solid;
}

.selectedModal, .visitedModal {
  background: var(--color5-c);
}


.slideNum {
  width: 20px;
  color: var(--color8);
  font-weight: 700;
  font-size: 14px;
}

.item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  user-select: none;
}
