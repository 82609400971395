.container {
    display: flex;
    padding: 10px 20px;
}

.justifyContentSpaceBetween {
    justify-content: space-between;
}

.justifyContentCenter {
    justify-content: center;
    gap: 15px;
}
