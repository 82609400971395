.b-canvas-demo-list {
  display: flex;
}

.b-demo-canvas {
  width: 500px;
  height: 500px;
  border: 1px solid yellow;
  overflow: hidden;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.b-demo-canvas_canvas { 
  width: 100%;
  height: 100%;
}