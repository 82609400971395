.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100vw;
    /*height: 100vh; !* Fallback for browsers that do not support Custom Properties *!*/
    /*height: calc(var(--vh-mobile) * 100);*/
    background-color:  #DDF3FC;
    overflow: hidden;
    padding-bottom: 70px;
    position: relative;
    height: 100%;

    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
}

.taskContainer {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-shrink: 2;

    margin-top: 10px;
    overflow: hidden;
    padding-bottom: 3px;
}

.hide {
    display: none;
}