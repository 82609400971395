.button {
  display: inline-block;
  border: 2px solid var(--primary);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  color: var(--primary);
  border-radius: 10px;
  line-height: 1;
  background: #ffffff;
  transition: all .2s ease-in;
  outline: none;
  height: 58px;
  padding: 18px 20px;

  &:hover {
    background: transparent;
    color: var(--primary);
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &_fill {
    background: var(--primary);
    color: #fff;
    &:hover {
      background: transparent;
      color: var(--primary);
    }
  }

  &_long {
    width: 188px;
    line-height: 1;
    padding: 10px 18px;
  }

  &_small {
    width: 77px;
    height: 22px;
    border: none;
    color: var(--primary);
    background: rgba( #F18825FF, 0.1);
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1;
    //margin-bottom: 10px;
  }

  &_medium {
    width: 110px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: normal;
  }

  &--small {
    padding: 5px 10px;
  }

}
