.avatar {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    max-width: 100%;
    max-height: 100%;
    margin: 0 0px;
}

.avatar img{
    max-width: 100%;
    max-height: 100%;
}

.mini {
    width: 30px;
    height: 30px;
}

.round {
    background-color: #e9f0fa;
    border-radius: 50%;
}

.round:nth-child(5n + 1) {
    background-color: var(--face-bg1);
}
.round:nth-child(5n + 2) {
    background-color: var(--face-bg2);
}
.round:nth-child(5n + 3) {
    background-color: var(--face-bg3);
}
.round:nth-child(5n + 4) {
    background-color: var(--face-bg4);
}
.round:nth-child(5n + 5) {
    background-color: var(--face-bg5);
}

.isRoundSvg {
    width: 40px;
    height: 40px;
}

.isNoRoundSvg {
    width: 60px;
    height: 60px;
}

.avatar .reaction {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -7px;
    top: -5px;
    z-index: 2;

}

.round.isSpeaking:after {
    content: '';
    background-image: url('./img/speaking.svg');
    background-repeat: no-repeat;
    width: 67px;
    height: 19px;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}