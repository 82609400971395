.radioWrapper
  display: flex
  margin-bottom: 10px

  input[type="radio"]
    margin-right: 10px
    appearance: none
    width: 17px
    min-width: 17px
    height: 17px
    border: 2px solid var(--border)
    border-radius: 50%
    background-clip: content-box
    padding: 1px
    outline: none
    box-sizing: border-box

  input[type="radio"]:checked
    background-color: var(--primary)
    border-color: var(--primary)
