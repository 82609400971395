.popapLessonEnd {
  display: flex;
  position: absolute;
  margin-top: -73px;
  z-index: 5;
}

.popapLessonEndArrow{
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #eeeded;
  margin-left: 55px;
}

.popapLessonEndText{
  width: 166px;
  height: 54px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(17, 48, 127, 0.2);
  font-size: 13px;
  line-height: 18px;
  color: #393F61;
  font-weight: 400;
  font-family: Manrope;
  margin-top: -27px;
  padding: 13px 16px 11px 15px;
  border-radius: 10px;
}
.popapLessonEndText span{
  font-weight: 700
}

.time{
  width: 60px;
  height: 15px;
}

.closePopap {
  position: absolute;
  background-color: transparent;
  border: none;
  top: -20px;
  right: 0;
  cursor: pointer;
  z-index: 99;
}
