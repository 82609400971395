.container {
    box-sizing: border-box;
    width: 100%;
    height: 55vh;
}

.chatBody {
    height: calc(100% - 60px) !important;
}

.chatControls {
    height: 60px !important;
    border-top: solid 1px #EAEEF1 !important;
}

.chatControls textarea {
    height: calc(100% - 20px) !important;
}