.wrap {
    position: fixed;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
}

.open {
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000034;
    z-index: 100;
    visibility: visible;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.inner {
    width: 100%;
    position: relative;
    max-width: 600px;
    padding: 40px 20px 20px;
    background: #fff;
    box-sizing: border-box;
    color: #393f61;

    box-shadow: 10px 10px 0px rgba(77, 163, 247, 0.27);
}

.innerAuto {
    width: auto;
    max-width: 100%;
}

.close {
    z-index: 2;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    margin-left: auto;
    position: absolute;
    right: 20px;
    top: 20px;
}

.buttons {
    margin: 0 -5px;
}

.buttons button {
    margin: 0 5px;
}

.title {
    text-align: center;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
}
