.wrap {
  display: flex;
  flex-wrap: wrap;
}

.item {
  position: relative;
}

.input {
  display: none;
}

.input:checked + .label {
  background: var(--primary);
  color: #fff;
}

.label {
  box-sizing: border-box;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: var(--primary);
  cursor: pointer;
}
