.teacherUnavailable
  height: 100%
  width: 100%
  position: relative
  display: flex
  background-color: var(--color5-b)
  box-sizing: border-box

  .foxImage
    height: 80%
    display: flex
    align-self: flex-end

  .text
    height: 30%
    display: flex
    align-self: flex-end
    margin-bottom: 5%
    margin-left: 5%

  .soundIcon
    position: absolute
    right: 5%
    bottom: 5%

  .img
    height: 80%
    width: 100%
    padding-top: 30px
