.container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    margin: auto;

    z-index: 3;

    border-radius: 35px;
    background-color: #FFFFFF;
    width: calc(100% - 10px);

    display: flex;
    flex-direction: column;
}