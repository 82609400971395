.container {
    position: absolute;

    bottom: 0;
    margin: auto;

    width: 100vw;
    background: #FFFFFF;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 3;
}

.title {
    position: absolute;

    top: 12px;
    left: 0;
    right: 0;
    margin: auto;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;

    color: #393F61;

    text-align: center;
}

.btnClose {
    position: absolute;

    top: 22px;
    right: 22px;

    border: none;
    background: transparent;
    padding: 0;
    margin: 0;

    z-index: 2;
}

.contentContainer {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    width: 100%;

    padding-left: 20.5%;
    padding-right: 9%;

    margin-top: 77px;
    margin-bottom: 35px;
    gap: 20px;

    overflow: auto;
}