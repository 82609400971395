.container {
    position: relative;
}

.hide {
    display: none;
}

.reaction {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 50px;
    width: 50px;
}

.reactionGalleryView {
    height: 20px;
    width: 20px;
}

.reaction svg {
    height: 100%;
    width: 100%;
}

.microIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    margin-left: auto;
    margin-top: auto;

    height: 24px;
    width: 24px;
}

.microIcon svg {
    height: 100%;
    width: 100%;
}
.displayName {
    max-width: 80%;
    position: absolute;
    left: 5px;
    bottom: 5px;
    margin-top: auto;

    border-radius: 6px;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.5);


    font-family: 'Circe';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 157%;

    text-align: center;

    color: #FFFFFF;
}