.btn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  height: 40px;
  width: 40px;
  cursor: pointer;
}
