.suggestionsContainer {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  right: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #FFF;
  border: 2px solid #f3f5f7;
  border-radius: 10px;
  overflow: hidden;
}

.suggestion {
  margin: 0;
  padding: 0 0 0 20px;
}

.suggestionActive, .suggestion:hover {
  background-color: #F1882533;
  cursor: pointer;
}
