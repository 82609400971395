.container {
  position: absolute;
  overflow: hidden;
  cursor: none;
  border-radius: 50%;
  border: 2px solid #4DA3F7;
  box-sizing: border-box;
  background-color: var(--color5-c);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.aim {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 8%;
  width: 8%;
  pointer-events: none;
}

.innerContainer {
  box-sizing: border-box;
  position: absolute;
}

.img {
  box-sizing: border-box;
  position: absolute;
  margin: unset;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.canvas {
  box-sizing: border-box;
  position: absolute;
}
