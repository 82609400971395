.lightTheme {
  --system-msg-background: rgba(57, 63, 97, 0.15);
  --mentioned-pupil-color: var(--color8);
}

.darkTheme {
  --system-msg-background: rgba(255, 255, 255, 0.3);
  --mentioned-pupil-color: var(--primary);
}

.systemMessage,.message .author, .time {
  font-size: 12px;
  line-height: 18px;
  font-family: Manrope, sans-serif;
  font-weight: 700;
}

.message {
  display: flex;
  //align-items: center;
  font-family: Manrope, sans-serif;

  &.classic {
    .msgBody {
      flex: 1 1;
      width: 5px; //хак, что бы не было горизонтадльного скрола
    }
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    overflow-wrap: break-word;
  }
}

.cloud {
  .msgBody {
    border-radius: 10px;
    color: var(--color8);
    padding: 9px 18px 12px;
    display: flex;
    max-width: 270px;
    .time {
      margin-left: 10px;
    }

    .bodyText {
      flex: 1 1;
      max-width: 230px;  //фикс горизонтального скрола
    }
  }
  .author {
    margin-bottom: 10px;
  }
  &.systemMessage {
    .msgBody {
      color: var(--text-color);
      width: 270px;
      border-radius: 14px;
      padding: 10px 20px 12px;
      background-color: var(--system-msg-background);
    }
    .supIcon {
      background-image: none;
    }
    .mentionedPupil {
      color: var(--mentioned-pupil-color);
      font-weight: 700;
    }
  }

  &.from{
    justify-content: flex-end;
    .msgBody {
      background-color: #FFEBC4;
      //margin-left: 19px;
      .time {
        color: #D47609;
      }
    }

    &:after{
      content:"";
      height: 15px;
      min-width: 19px;
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-image: url("../../img/cloud_tail.svg");
      background-color: #FFEBC4;
      align-self: flex-end;
      position: relative;
      right: 10px;
    }
  }

  &.to:not(.systemMessage){
    align-items: flex-end;
    .msgBody {
      background-color: #BADDFB;
      //margin-right: 19px;
      .time {
        color: #4DA3F7;
      }
    }
      &:before {
        content: "";
        height: 15px;
        min-width: 19px;
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-image: url("../../img/cloud_tail.svg");
        background-color: #BADDFB;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        align-self: flex-end;
        position: relative;
        left: 31px;
      }
  }

  .supIcon {
    margin: 0 11px 0 -19px;
  }
}

.cloudOutside {
  //align-items: flex-end;
  .msgBody {
    background-color: #BADDFB;
    width: 250px;
    justify-content: space-between;

    .closeIcon {
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-image: url("../../img/del.svg");
      background-color: var(--color8);
      cursor: pointer;
      height: 8px;
      min-width: 8px;
    }
  }
}

.timeAndButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-end;
}

.buttons {
  display: flex;
  gap: 10px;
}

.tooltip {
  background: #FFFFFF !important;
  /* IQ shadow */
  box-shadow: 0px 2px 10px rgba(17, 48, 127, 0.2);
  border-radius: 10px !important;
  padding: 13px 13px 11px 15px !important;
  opacity: 1 !important;
  color: #393F61 !important;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.time {
  font-weight: 400;
  color: var(--color8-a);
}

.systemMessage {
  color: var(--system-msg);
  margin-left: 40px;
}

/*
.supervisorMessage .author {
  color: var(--color7)
}*/

.supIcon {
  display: inline-flex;
  min-width: 29px;
  height: 25px;
  margin: 5px 11px 0 0;
  background-image: url("../../img/avatar-fox.svg");
  background-size: cover;
  position: relative;
  top:2px;
}

.mentionedPupil {
  font-weight: 700;
}

.mobileWrap {
  &.to .msgBody {
    margin-right: 19px;
    max-width: calc(100% - 70px);
  }
  &.from .msgBody {
    margin-left: 40px;
    max-width: calc(100% - 59px);
  }
  &.systemMessage {
    .msgBody {
      color: var(--color8);
      background-color: rgba(57, 63, 97, 0.15);
      width: calc(100% - 49px);
    }
    .mentionedPupil {
      color: var(--color8);
    }
  }
  .text {
    overflow-wrap: anywhere;
  }
}

.newAuthor {
  margin-top: 10px;
}