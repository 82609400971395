.imgContainer {
  width: 100%;
  height: 100%;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.inner {
  background-size: contain;
  background-position: center;
  position: relative;
  width: 100%;
  /*//aspect-ratio: 5 / 7;*/
  /*    aspect-ratio: 3 / 3;*/
  background-repeat: no-repeat;
  flex-shrink: 0;
  margin: 0 auto;
  height: 100%;
  /*height: max-content;*/
  /*//max-height: 100%;*/
}

.tooltip {
  z-index: 99999999999999 !important;
  max-width: 170px !important;
  font-weight: normal;
  font-size: 14px;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  border-radius: 10px;
  display: flex !important;
  align-items: flex-end!important;
}

.hide {
  display: none !important;
}

.callToSupportImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  margin: auto;
}
