.container {
    box-sizing: border-box;
    background-color: rgba(77, 163, 247, 0.3);
    height: 10px;
    width: 86%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

    border-radius: 5px;
    overflow: hidden;
}

.timer {
    border-radius: 5px;
    height: 100%;
    background-color: #4DA3F7;
}