.wrap {
    width: 100%;
    background: #000;
    position: absolute;
    box-sizing: border-box;
}

.video {
    height: 0;
}

.isStart {
    padding-bottom: 15px;
    z-index: 3;
}

.isStart, .isStart .video {
    height: 100%;
}

.wrap video {
    width: 100%;
    height: 100%;
}

.controls {
    position: absolute;
    left: 10px;
    top: 20px;
    z-index: 3;
}

.button {
    cursor: pointer;
}