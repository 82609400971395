.wrapper {
  display: flex;
  background-color: var(--blue-background);
  justify-content: space-between;
  height: 80px;
  box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);
  z-index: 1;
}

.wrapper:not(.inner){
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.content{
  display: flex;
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  color: var(--color8);
  line-height: 21px;
  align-items: center;
  padding: 10px 0;
}

.blueText {
  color: var(--primary)
}

.closeIcon {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  width: 8px;
  height: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../img/del.svg");
  bottom: -5px;
  cursor: pointer;
}

.icon {
  display: inline-block;
  position: relative;
  margin: 0 5px;
  top: 6px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
}

.chromeIcon {
  background-image: url("../../img/chrome.svg");
}

.firefox {
  background-image: url("../../img/firefox.svg");
}

.opera {
  background-image: url("../../img/opera.svg");
}

.safari {
  background-image: url("../../img/safari.svg");
}

.microsoft-internet-explorer {
  background-image: url("../../img/explorer.svg");
}

.foxImage {
  height: 60px;
  width: 98px;
  background-image: url("../../img/fox-info.png");
  align-self: flex-end;
  margin: 0 20px -10px;
}

@media screen and (max-width: 1050px){

}

@media screen and (max-width:880px) {
  .wrapper {
    height: auto;
  }
  .content {
    flex-direction: column-reverse;
    margin: 15px 20px 0;
    line-height: 30px;
  }
  .foxImage {
    align-self: flex-start;
  }
}