.wrap {
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
    color: #393f61;
    margin: 5px 0;
}

.options {
    display: flex;
    align-items: center;
}

.option {
    font-size: 14px;
    line-height: 32px;
    color: #b4b4b4;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

.current {
    cursor: default;
    color: #393f61;
}

.switch {
    background: none;
    border: none;
    padding: 0;
    margin: 5px 14px;
    background: #d8ecf5;
    border-radius: 50px;
    width: 30px;
    height: 12px;
    position: relative;
    cursor: pointer;
}

.switch:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    background: #4da3f7;
    border-radius: 50px;
    transition: all 0.4s;
}

.switch.option1:before {
    margin-left: -3px;
    left: 0;
}

.switch.option2:before {
    margin-left: -15px;
    left: 100%;
}