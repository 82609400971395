.wrap {
    margin: 0.5em 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    position: relative;
    padding-left: 25px;
    display: block;

    color: var(--primary);
}

.txt {
    color: #393f61;
}

.input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.checkmark {
    background: #ffffff;
    width: 18px;
    height: 18px;
    border: 2px solid #859db4;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    color: inherit
}

.checkmarkInner {
    display: none;
    fill: currentColor;
}

.input:checked + .checkmark {
    border: none;
}

.input:checked + .checkmark .checkmarkInner{
    display: block;
}

.input:checked + .checkmark.currentColor {
    color: inherit;
}