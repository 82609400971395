.wrap {
    max-width: 736px;
    overflow: hidden;
    margin: 0 auto 25px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 25px;
    padding: 0 10px;
}

.carouselButtons {
    display: flex;
}

.carouselPrev,
.carouselNext {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #ff8514;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: none;
    color: #fff;
}

.portal .carouselPrev,
.portal .carouselNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #ced0d1;
    color: #5c6266;
}

.portal .carouselPrev {
    right: 100%;
    margin-right: 5px;
}

.portal .carouselNext {
    left: 100%;
    margin-left: 5px;
}

.carouselPrev svg {
    margin-left: -2px;
}

.carouselNext svg {
    margin-left: 2px;
}
.carouselNext {
    margin-left: 11px;
}

.carouselPrev:disabled,
.carouselNext:disabled {
    opacity: 0.5;
    cursor: default;
    display: none;
}

@media only screen and (max-width: 1024px) {
}

.galleryItemsWrap {
    overflow: hidden;
    width: 100%;
}

.galleryItems {
    white-space: nowrap;
    padding: 0;
    list-style: none;
    margin: 0;
    transition: margin-left 250ms;
}

.galleryItems li {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
}

.item {
    white-space: normal;
    width: 164px;
    height: 189px;
    position: relative;
    padding: 0;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    /* or 123% */
    background: #3381dc;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        180deg,
        rgba(51, 129, 220, 0) 0%,
        #1d71d2 70.08%
    );
}

.item img {
    position: absolute;
    left: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    right: 0;
}

.itemTitle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: left;
    padding: 12px 15px;
    white-space: pre-line;
}

.portal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.portalInner {
    position: relative;
}

.portalClose {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 10px;
}

.story {
    background: #393f61;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* or 122% */
    width: 100%;
    color: #caccdb;
}

.storyImg {
    width: 100%;
    position: relative;
    height: 330px;
    overflow: hidden;
}

.storyUi {
    background-color: #DDF3FC;
  height: 100%;
}

.storyUi .storyImg {
    height: 100%;
}

.storyUi .storyImg:after {
    display: none;
}

.storyUi .storyImg img{
    object-fit: contain;
    object-position: top;
}

.storyImg img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    max-height: 100%;

    object-fit: cover;
    height: 100%;
    width: 100%;
}

.storyImg:after {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    background: linear-gradient(
        180deg,
        rgba(57, 63, 97, 0) 64.94%,
        #393f61 97.88%
    );
}

.storyImg + .storyHeader {
    margin-top: -25px;
}

.storyHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #fff;
    padding: 0 20px 17px;
    position: relative;
    z-index: 2;
}

.storyContent {
    padding: 0 20px;
    font-size: 16px;
    line-height: 22px;
}

.storyContent ul {
    margin: 0;
    padding-left: 20px;
}

.storyContent li {
    margin: 8px 0;
}

.storyContent li:first-child {
    margin-top: 0;
}

.storyContent li:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 1024px) {
    .top {
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        justify-content: center;
    }
    .galleryItemsWrap {
        overflow: auto;
    }
    .storyImg {
        height: 238px;
    }
    .storyHeader {
        font-size: 25px;
    }
    .portalClose {
        left: auto;
        right: 8px;
        z-index: 1000;
        top: 15px;
        margin-left: 10px;
    }

    .portalInner {
        overflow: hidden;
    }
}

@media only screen and (max-width: 1024px) {
    .storyImg {
        height: 238px;
    }
    .portalClose {
        left: auto;
        right: 8px;
        z-index: 1000;
        top: 15px;
        margin-left: 10px;
    }
}
