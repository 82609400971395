.button {
  display: inline-block;
  border: 1px solid var(--color7-a);
  font-size: 18px;
  cursor: pointer;
  color: var(--color7-a);
  border-radius: 10px;
  line-height: 1;
  background: #ffffff;
  transition: all .2s ease-in;
  outline: none;
  height: 58px;
  //margin-left: 14px;
  padding: 19px 21px;

  &:hover {
    filter: brightness(97%);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &_fill {
    border: none;
    background: var(--color7-a);
    color: #fff;

    &:hover {
      background: var(--color7);
    }
  }

  &_long {
    min-width: 188px;
    //font-size: 14px;
    line-height: 1;
    //height: 42px;
    padding: 12px 20px;
  }

  &_small {
    width: 77px;
    height: 22px;
    border: none;
    color: var(--color7-a);
    background: rgba( #F18825FF, 0.1);
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1;
    //margin-bottom: 10px;
  }

  &_medium {
    width: 130px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  &--small {
    padding: 5px 10px;
  }

}
