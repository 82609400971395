.page-login
  padding-top: 100px
  display: flex
  align-items: center
  height: 100%
  flex-direction: column
  box-sizing: border-box

  .title-login
    width: 380px
    text-align: center
    color: var(--color8)
    margin: 0

  .mb-20
    margin-bottom: 20px

  .mb-60
    margin-bottom: 60px

  .form-login,
  .form-recovery-password
    align-items: center
    display: flex
    flex-direction: column
    width: 380px
    box-sizing: border-box

    label
      box-sizing: border-box
      width: 100%
      height: 64px

    .input
      position: relative
      display: flex
      align-items: center
      padding: 20px
      border-radius: 10px
      width: 380px
      height: 64px
      color: var(--color8)
      background: var(--color8-b)

      input
        position: absolute
        left: 20px
        font-size: 18px
        line-height: 30px
        border: none
        outline: none
        background: transparent
        padding: 0
        width: 240px
        font-family: Manrope, sans-serif
        color: var(--color8)
        transition: none

      .label
        color: var(--color8-a)
        position: absolute
        left: 20px
        font-size: 18px
        line-height: 30px

      &.active
        background: #fff
        border: 2px solid var(--border)

        input
          bottom: 5px
          background: #fff

        input + .label
          font-size: 14px
          line-height: 19px
          top: 10px

        &.error
          color: var(--danger)
          border: 2px solid var(--danger)
          background: #fff

          .label
            color: red
            opacity: 1

          .input-icon
            fill: var(--danger)

    button
      padding-left: 50px
      padding-right: 50px
      outline: none

    .link-login
      background-color: transparent
      border: none
      outline: none
      color: var(--color7-a)
      font-size: 14px
      cursor: pointer

    .login-error
      font-size: 14px
      height: 20px
      margin-bottom: 20px
      margin-top: 20px
      color: var(--danger)
      display: flex
      align-self: flex-start
