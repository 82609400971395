.container {
    /*flex: 1 1 0;*/
    height: 100%;
    width: 100%;
    position: relative;
}
.video {
    height: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    background-color: black;
}