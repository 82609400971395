.wrap {
 position: fixed;
 left: 15px;
 top: -100%;
 width: 800px;
 height: 710px;
 min-width: 200px;
 min-height: 200px;
 max-height: calc(100vh - 30px);
 max-width: calc(100vw - 30px);
 visibility: hidden;
 transition: top 0.5s;
 background-size: contain;
 z-index: 80;
 box-sizing: border-box;
 background: var(--color5-c);
 box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);
 border-radius: 10px;
 overflow: hidden;
 resize: both;
 display: flex;
 flex-direction: column;

 &.fullScreen {
  height: 100vh;
  width: 100vw;
  resize: none;
 }
}

.wrap img {
 user-select: none;
}

.wrap img {
 user-select: none;
}

.top {
 position: relative;
 height: 20px;
 margin-bottom: 10px;
 margin-right: 20px;
 box-sizing: border-box;
 display: flex;
 flex-direction: row;
}

.dragZone {
 z-index: 3;
 cursor: move;
 color: var(--primary);
 flex-shrink: 0;
 position: relative;
 width: 100%;
 height: 100%;
}

.close {
 cursor: pointer;
 border: none;
 padding: 0;
 margin: 0;
 background: none;
 outline: none;
 display: flex;
 align-items: flex-end;
}

.close:active,
.close:focus {
 outline: none;
}

.wrap img {
 width: 100%;
 height: 100%;
 object-fit: contain;
 position: absolute;
 margin: auto;
 /* display: none; */
}

.wrap.isShow {
 visibility: visible;
 top: 15px;
}

.taskSlot {
 color: var(--primary);
 display: flex;
 flex-direction: row;
 box-sizing: border-box;
 max-width: 100%;
 position: relative;
}

.time {
 margin-right: 5px;
 font-size: 14px;
 color: var(--primary);
}

.timer {
 position: absolute;
 font-weight: bold;
 width: 60px;
 max-width: 60px;
 top: 30px;
 display: flex;
 z-index: 5;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 box-sizing: border-box;
 margin-left: 10px;

 &.started {
  margin-left: 10px;
 }
}

.timerIcon {
 min-height: 16px;
 min-width: 16px;
}

.resizeZone {
 position: absolute;
 z-index: 3;
 right: 0;
 bottom: 0;
 height: 20px;
 width: 20px;
 cursor: nwse-resize;
 overflow: hidden;
}

.resizeZone:before {
 content: "";
 height: 40px;
 width: 1px;
 background: var(--primary, blue);
 transform: rotate(45deg);
 position: absolute;
 left: 50%;
 top: 50%;
 margin-top: -15px;
 box-shadow: 5px 0px 1px 0px var(--primary, blue);
}

.content {
 position: relative;
 flex-shrink: 0;
 height: 100%;
 width: 60%;
 flex-grow: 1;
 box-sizing: border-box;
}

.taskDescription {
 position: relative;
 width: 35%;
 margin-left: 20px;
 box-sizing: border-box;
 display: flex;
 flex-direction: column;
 color: var(--color8);
}

.taskSelectContainer {
 color: var(--primary);
 padding-bottom: 20px;
}

.slideSelectContainer {
 max-height: 18px;
}

.teacherDesc {
 position: relative;
}

.description {
 margin-bottom: 20px;
 position: relative;
}

.hideButton {
 position: absolute;
 right: 10px;
 top: 5px;
 font-family: Manrope, serif;
 font-style: normal;
 background: #4da3f7;
 color: white;
 border: none;
 cursor: pointer;
 user-select: none;
 transition: opacity ease-in 0.2s;
 font-size: 14px;
 line-height: 24px;
 padding: 5px;
}

.hideButton:hover {
 opacity: 1;
}

.taskName {
 font-family: Manrope, sans-serif;
 font-style: normal;
 font-size: 18px;
 line-height: 24px;
 font-weight: 700;
 color: #393f61;
 margin-top: 0;
 margin-bottom: 1em;
}

.taskAnswer {
 font-family: Manrope, sans-serif;
 font-style: normal;
 font-size: 16px;
 line-height: 22px;
 font-weight: 700;
 color: #393f61;
 margin: 0;
}

.review {
 height: auto;
 max-width: 250px;
}

.descriptionWrap {
 overflow-y: auto;
 box-sizing: border-box;
 position: relative;
 padding: 0 20px 20px 0;

 &::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  cursor: pointer;
 }

 &::-webkit-scrollbar-thumb {
  border-radius: 4px;
  height: 50%;
  background-color: var(--primary);
  cursor: pointer;
 }
}

//.descriptionWrap:after {
//  content: "";
//  position: absolute;
//  left: 0;
//  width: 100%;
//  z-index: 3;
//  pointer-events: none;
//  bottom: 0;
//  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70.31%, #FFFFFF 100%);
//  height: 30%;
//  //max-height: 75px;
//}

.taskContent {
 display: flex;
 justify-content: flex-start;
 height: calc(100% - 50px);
 box-sizing: border-box;
}

.select {
 padding: 0;
}

.carouselSlot {
 position: relative;
 min-width: 280px;
 width: 100%;
 bottom: 0;
 background: #fff;
}

.angle {
 position: absolute;
 bottom: 0;
 right: 0;
}

.modeOptionBtn {
 background: none;
 border: none;
 width: 40px;
 height: 40px;
 margin-bottom: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;

 &:focus,
 &:active {
  outline: none;
 }

 &.infoTooltip {
  cursor: default;
 }
}
