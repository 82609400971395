.wrap {
 width: 100%;
 height: 100%;
 box-sizing: border-box;
 position: relative;
 display: flex;
 align-items: baseline;
}

.hide {
  display: none !important;
}

.callToSupportImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  margin: auto;
}

.buttons {
 width: 80px;
 z-index: 3;
 background: var(--color5-c);
 display: flex;
 align-self: flex-start;
 flex-direction: column;
 padding: 40px 0 0 20px;
 box-sizing: border-box;
 flex-shrink: 0;
}

.button {
 border-radius: 2px;
 width: 40px;
 height: 40px;
 background: #fff;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 0;
 margin: 0;
 border: none;
 cursor: pointer;
}

.imgContainer {
 width: 100%;
 height: 100%;
 border-radius: 6px;
 display: flex;
 align-items: center;
 position: relative !important;
 overflow: hidden;
}

.imgContainerForLargeTask {
 width: calc(100% - 80px);
}

.inner {
 background-size: contain;
 background-position: center;
 position: relative;
 //width: 100%;
 //aspect-ratio: 5 / 7;
 //aspect-ratio: 3 / 3;
 //aspect-ratio: 1 / 2;
 background-repeat: no-repeat;
 flex-shrink: 0;
 margin: 0 auto;
 //height: max-content;
 //max-height: 100%;
 //max-height: 98vh;

 //@supports not (aspect-ratio: 3 / 3) {
 //  padding-top: 100%;
 //}
}

.canvas {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 max-height: 100%;
}

.wrap_pencil .canvas,
.wrap_pointer .canvas {
 cursor: url("./img/cursor.svg") 7 7, auto;
}

.readOnly .canvas {
 cursor: auto;
}

.taskContainer {
 width: 100%;
 height: 100%;
 overflow: auto;

 img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
 }
}

.modeOptionBtn {
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

 &:disabled {
  opacity: 0.5;
 }

 &.selected {
  background: var(--primary);
  border-radius: 2px;
 }

 &:focus,
 &:active {
  outline: none;
 }
}
