.pcContent {
  /*flex: 1 0 auto;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pcWrapBG {
  min-height: 100vh;
  background-image: url("./img/bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.pcContent h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #393F61;

  margin-top: 0;
  margin-bottom: 16px;
}

.pcContent p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  color: #393F61;

  padding: 0;
  margin-top: 0;
  margin-bottom: 62px;
}

.buttonContainer {
  display: flex;
  gap: 20px;
}

.btn {
  display: block;
  text-decoration: none;
  width: 200px;
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.footer {
  position: absolute;
  bottom: 40px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #393F61;
}

.footer a {
  text-decoration: none;
  color: #393F61;
}

@media only screen and (max-width: 767px) {

  .pcContent {
    padding-left: 23px;
    padding-right: 23px;
    box-sizing: border-box;
  }

  .pcContent h1 {
    font-size: 32px;
    line-height: 38px;
  }

  .pcContent p {
    font-size: 22px;
    line-height: 32px;
  }

  .buttonContainer {
    flex-direction: column;
    width: 100%;
  }

  .btn {
    display: block;
    width: 100%;
  }
}
