.pcContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pcWrapBG {
    min-height: 100vh;
    background-image: url("./imgs/bg.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.pcContent h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    color: #393F61;

    margin-top: 81px;
    margin-bottom: 28px;
}

.fox {
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-height: 477px;
    text-align: center;
}

.fox img {
    max-width: 100%;
    position: relative;
    z-index: 1;
    object-fit: contain;
}

.iqTokenContainer {
    /*position: absolute;*/
    /*z-index: 2;*/
    width: 300px;
    height: 130px;

    display: flex;
    /*gap: 28px;*/
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    background: #FFFFFF;
    opacity: 0.95;
    box-shadow: 10px 10px 50px rgba(77, 163, 247, 0.45);
    border-radius: 16px;

    padding: 20px 38px 29px;
}

.iqTokenContainer .columnContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.iqTokenContainer .divider {
    height: 69px;
    width: 1px;
    background-color: #CED8EC;
}

.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 71%;
    /*height: 24px;*/

    text-align: center;

    color: #393F61;
}

.iqTokenContainer .iqTokenCount {
    display: flex;
    align-items: center;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;

    text-align: center;

    color: #4DA3F7;
}

.iqTokenContainer .iqTokenCount svg {
    margin: auto;
    margin-left: 5px;
    height: 36px;
    width: auto;
}

.pupilReactionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: space-between;*/
    width: 300px;
    /*height: 130px;*/
    box-sizing: border-box;

    background: #FFFFFF;
    opacity: 0.95;
    box-shadow: 10px 10px 50px rgba(77, 163, 247, 0.45);
    border-radius: 16px;

    padding-top: 27px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 31px;
}

.buttonLink {
    margin-top: 30px;

    box-sizing: border-box;
    text-decoration: none;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 13px;

    background: #F18825;
    border: 1px solid #F18825;
    border-radius: 10px;

    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 171%;

    color: #FFFFFF;
}

.buttonLink:hover {
    transition: 0.3s;
    background: var(--color7);
}

.pupilReactionContainer .link {
    margin-top: 15px;
    text-decoration: none;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;

    text-align: center;

    color: #F18825;
}

.pupilReactionContainer .link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.reactionsBtn {
    display: flex;
    gap:  40px;

    margin-top: 25px;
}

.reactionButton {
    cursor: pointer;
    
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;

    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
}

.chosenReaction {
    margin-top: 30px;

    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58%;
}

.leftContainer {
    position: absolute;
    z-index: 2;
    top: 0;
    right: calc(100% - 100px);


    display: flex;
    flex-direction: column;
    gap: 20px;
}

.foxSay {
    position: absolute;
    z-index: 2;
    left: calc(100% - 40px);
    bottom: calc(100% - 204px);

    padding: 12px 20px 14px;
    background: #FFFFFF;
    border-radius: 16px;

    box-sizing: border-box;
    height: 82px;
    width: 152px;

    box-shadow: 10px 10px 50px rgba(77, 163, 247, 0.45);

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 156%;

    color: #393F61;

    text-align: start;
}

.foxSay:before {
    content: '';
    width: 30px;
    height: 24px;
    position: absolute;
    bottom: 20px;
    left: -21px;
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-image: url("../../img/cloud_tail.svg");
    background-color: #fff;
    /*transform: matrix(-1, 0, 0, 1, 0, 0);*/
    transform: matrix(-0.77,-0.64,-0.64,0.77,0,0);

    box-shadow: 10px 10px 50px rgba(77, 163, 247, 0.45);
}

.orangeFoxSay {
    background: #F15700;
    color: #FFFFFF;
}

.orangeFoxSay:before {
    background: #F15700;
}


@media only screen and (max-width: 767px) {
    .pcContent {
        max-height: 100vh;
        overflow: hidden;
    }

    .pcContent h1 {
        margin-top: 22px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 119%;

        text-align: center;
    }

    .fox {
        margin-left: 36px;
        margin-right: 55px;
    }

    .foxSay {
        bottom: calc(100% - 55px);
        left: 0;
        right: 0;
        margin: auto;
        width: 230px;
        height: 58px;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }

    .foxSay:before {
        transform: matrix(-1, 0, 0, 1, 0, 0);
        top: calc(100% - 5px);
        left: calc(100% - 70px);
    }

    .leftContainer {
        box-sizing: border-box;
        padding-right: 20px;
        padding-left: 20px;

        width: 100vw;
        gap: 10px;
        top: unset;
        bottom: 0;
        left: -36px;
        margin-bottom: 45px;

        align-items: center;
    }

    .withReaction {
        margin-bottom: 15px;
    }

    .iqTokenContainer {
        width: 100%;
        max-width: 320px;
        height: 90px;

        padding-top: 10px;
        padding-bottom: 18px;
        justify-content: space-around;
    }

    .iqTokenContainer .columnContainer {
        gap: 10px;
    }

    .iqTokenContainer .iqTokenCount {
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
    }

    .iqTokenContainer .iqTokenCount svg {
        height: 30px;
        width: auto;
    }

    .iqTokenContainer .divider {
        height: 55px;
    }

    .pupilReactionContainer {
        width: 100%;
        max-width: 320px;
        min-height: 90px;

        padding-top: 17px;
        padding-bottom: 11px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .reactionButton {
        font-weight: 700;
        font-size: 32px;
        line-height: 100% ;
    }

    .reactionsBtn {
        margin-top: 10px;
    }

    .chosenReaction {
        margin-top: 8px;

        font-size: 36px;
        line-height: 36px;
    }

    .buttonLink {
        margin-top: 15px;
        max-height: 42px;
        padding-top: 8px;
    }
}



