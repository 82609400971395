.button {
 width: 100%;
 display: block;
 cursor: pointer;
 box-sizing: border-box;
 color: #fff;
 background: #4da3f7;
 border-radius: 6px;
 height: 45px;
 margin: 30px 0 20px;
 max-width: 280px;
 border: none;
 font-style: normal;
 font-size: 14px;
 line-height: 24px;
 text-align: center;
 letter-spacing: 0.1em;
}

.viewToggle {
 display: flex;
 align-items: center;
 color: #393f61 !important;
}

.viewToggle span {
 margin-left: 5px;
}

.viewIcon {
 width: 25px;
 height: 25px;
 fill: #4da3f7 !important;
}

.viewLabel {
 font-weight: bold;
 font-size: 14px;
 line-height: 20px;
 color: #4da3f7;
}

.selectTaskWrap {
 max-width: 280px;
 color: #393f61;
}

.selectTaskWrap_1 {
 color: var(--color1);
}

.selectTaskWrap_2 {
 color: var(--color2);
}

.selectTaskWrap_3 {
 color: var(--color3);
}

.selectTaskWrap_4 {
 color: var(--color4);
}

.selectTaskWrap_5 {
 color: var(--color5);
}

.selectTaskWrap_6 {
 color: var(--color6);
}
