.wrapper {
  position: absolute;
  bottom: 0;
  right: 20px;
  z-index: 20;
  height: 164px;
  width: 335px;
  display: none;
}

.wrapper.isShow{
  display: flex;
}

.messageWrapper {
  width: 195px;
  max-height: 59px;
  height: max-content;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  color: var(--color8);
  background-color: #FFB859;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7px 13px;
  box-shadow: 5px 5px 0 rgba(77, 163, 247, 0.5);
  border-radius: 10px;
  position: relative;
  margin-top: 39px;

  &:after {
    content:"";
    height: 15px;
    min-width: 19px;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-image: url("../../../img/cloud_tail.svg");
    background-color: #FFB859;
    position: absolute;
    left: calc(100% - 10px);
    bottom: 0;
  }
}

.foxImage {
  height: 164px;
  width: 114px;
  background-image: url("../../img/messages_fox.png");
  background-size: cover;
  background-position: 70%;
  background-repeat: no-repeat;
}

.message {
  font-weight: 400;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height:42px;
}

.title {
  font-weight: 700;
}