.wrap {
    position: relative;
    color: var(--thema-color);
}

.wrap svg {
    transform: rotate(-90deg);
    display: block;
}

.wrap circle {
    transition: stroke-dashoffset 1s linear;
}


