.container {
  width: 100%;
  height: 100%;
  margin: 0;

  background-color: #E5F6FF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  max-width: 1053px;
  width: 100%;
  margin-top: 20px;
  justify-content: space-around;
}

.header span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #393F61;
  opacity: 0.5;
}

.header a {
  text-decoration: none;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #393F61;
}

.logo {
  width: 62px;
}

.content {
  margin-top: 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  flex-grow: 1;
}

.content h1 {
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  text-align: center;
  color: #393F61;
  margin-bottom: 36px;

  margin-top: 0;
}

.cards {
  display: flex;
  gap: 30px;
}

.imageContainer {
  position: relative;
  width: 80px;
  height: 105px;
  overflow-y: hidden;
  border-radius: 0 0 40px 40px;
}

.imageBackground {
  position: absolute;
  bottom: 0;
  background-color: #67A6D9;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.imageBackground img {
  width: 100%;
  object-fit: cover;
}

.card {
  box-sizing: border-box;

  width: 234px;
  height: 262px;
  background-color: #FFFFFF;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px;
}

.card p {
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #393F61;
}

.btn {
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 0 !important;
  width: 100%;
}
@media only screen and (max-width: 767px){
  .container{
    height: auto;
  }
  .span{
    display: none;
  }
  .header{
    justify-content: space-evenly;
  }
  .cards{
    flex-direction: column;
  }
}

.buttonNext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid var(--color7-a);
  font-size: 18px;
  padding: 0 30px;
  cursor: pointer;
  color: var(--color7-a);
  border-radius: 10px;
  line-height: 20px;
  background: #fff;
  transition: all .3s ease-in;
  outline: none;
  height: 58px;

  &.thin {
    width: 180px;
    height: 42px;
    font-size: 14px;
  }

  &:hover {
    border-color: var(--color7-b);
    color: var(--color7-b);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &.long {
    width: 280px;
    display: flex;
    justify-content: center;
  }

  &--fill {
    border: none;
    background: var(--color7-a);
    color: #fff;
    height: 58px;


    &:hover {
      background: var(--color7);
      color: #FFF;
    }
  }

  &--small {
    padding: 5px 10px;
  }
}