.btn
  cursor: pointer
  padding: 0
  margin: 0
  border: none
  background: none
  color: inherit

.icon
  fill: currentColor

.selectField
  margin-bottom: 15px
  position: relative
  background: #fff

.wrap .select
  width: 100%
  padding-top: 21px
  padding-left: 14px
  position: relative
  background-color: transparent

.wrap .updateDevices
  margin-bottom: 20px
  font-style: normal
  font-weight: bold
  font-size: 16px
  line-height: 30px
  color: var(--primary)
  display: flex
  align-items: center

.label
  padding-left: 14px
  position: absolute
  top: 0
  left: 0
  font-family: Manrope
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 34px
  color: #899ba6

.iconUpdate
  stroke: currentColor
  margin-right: 5px
