.wrap {
    position: relative;
    max-width: 100%;
    padding: 15px 0;
    flex-shrink: 0;
    /*flex-grow: 2;*/
}

.values {
    display: flex;
}

.tooltip {
  border-radius: 6px;
  padding: 15px 10px!important;
  opacity: 1!important;
  box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);
}

.value {
    flex-shrink: 0;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    padding: 0 5px;
    border: none;
    background: url("./img/arrow.svg") no-repeat right center;
    outline: none;
    min-width: 0;
    color: inherit;
}

.value:focus {
    outline: none;
}

.value:first-child {
    margin-left: 0;
    padding-left: 0;
}

.arrowContainer{
    display: inline;
    overflow: hidden;
    width: 10px;
    height: 100%;
    position: absolute;
    right: -5px;
    top: 0;
}

.arrowContainerLeft {
    display: inline;
    overflow: hidden;
    width: 10px;
    height: 100%;
    position: absolute;
    left: -5px;
    top: 0;
}

.valueTxt {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding: 0 5px;
    box-sizing: border-box;


    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: block;
    /* or 187% */

    text-align: center;

    /* color: var(--primary, #4da3f7); */
    color: currentColor;
}

.valueLast {
    flex: 1;
    background: none;
    padding-right: 0;
    margin-left: -2px;
}

.valueLast .valueTxt{
    text-align: left;
    padding-right: 0;
}

.svg {
    fill: currentColor;
    stroke: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.arrowOn {
    display: none;
}

.arrowOnLeft {
    left: 0;
}

.arrowOnRight {
    right: 0;
}

.arrow {
    right: 0;
    fill: none !important;
}

.valueOn .arrowOn {
    display: block;
    background: none;
    /* background: url("./img/arrowOn.svg") left center no-repeat,
        url("./img/arrowOn.svg") right center no-repeat; */
    outline: none;
}
.valueOn .arrow {
    display: none;
}

.valueInner {
    display: block;
}

.valueOn .valueInner {
    background: currentColor;
}

.isSelected .valueOn .valueInner {
    background: #fff;
}

.isSelected .valueOn .svg {
    fill: #fff;
    stroke: #fff;
}

.isSelected .arrow {
    stroke: #fff;
}

.valueOn .valueTxt,
.isSelected .valueTxt {
    color: #fff;
}

.isSelected .valueOn .valueTxt {
    color: currentColor;
}

.panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 10;
    color: currentColor;
    background: #fff;
    box-sizing: border-box;
    padding: 0 10px;
    overflow: hidden;
    min-width: 190px;
}

.panels_top .panel {
    top: auto;
    bottom: 100%;
}

.panelInner {
    position: relative;
    /* z-index: 2; */
    max-height: 0;
    overflow: auto;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
}

.panel:before,
.panel:after {
    content: "";
    position: absolute;
    left: 0;
    width: calc(100% - 17px);
    z-index: 3;
    pointer-events: none;
}

.panel:after {
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(77, 163, 247, 0) -9.24%,
        #fff 60.29%
    );
    height: 22%;
    max-height: 75px;
}

.panelInner::-webkit-scrollbar {
    width: 5px;
}

.panelInner::-webkit-scrollbar-track {
    background: none;
}

.panelInner::-webkit-scrollbar-thumb {
    background: var(--primary, #4da3f7);
    background: currentColor;
    border-radius: 5.68621px;
    /* z-index: 3;
    position: relative; */
}

.panelOpen {
    padding: 10px;
}

.panelOpen .panelInner {
    max-height: 309px;
    min-height: 309px;
    padding: 10px 10px 45px 0;
}

.list {
    counter-reset: item;
    padding-left: 0;
}

.item {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: block;
    cursor: pointer;
    padding: 6px 10px 6px 50px;
    font-family: Manrope, sans-serif;
    line-height: 20px;
    border-radius: 6px;
}

.item:hover {
    background: currentColor;
    opacity: 0.8;
}

.item:hover .itemTxt {
    color: #fff;
}

.item:before {
    /* content: counter(item, decimal-leading-zero) " "; */
    content: attr(data-index) " ";
    /* counter-increment: item; */
    display: block;
    float: left;
    margin-left: -40px;
}

.itemVisited {
  background-color: #4DA3F74D;
}

.itemOn {
    font-weight: bold;
    cursor: default;
    background: currentColor;
    opacity: 1;
}
.itemOn .itemTxt{
    color: #fff;
}

.itemOn:before, .item:hover:before {
    color: #fff
}

.panelTitle {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 1em;
}

.carouselSlot {
    min-height: 49px;
    position: relative;
    min-width: 188px;
    background: #fff;
    border-radius: 6px;
    padding: 2px 0;
}

.pupilForPresenter.wrap {
  padding: 7px 0;
}

.pupilForPresenter .arrowContainer {
  height: 16px;
}

.pupilForPresenter .valueTxt {
  line-height: 16px;
}

.pupilForPresenter.carouselSlot {
  border-radius: 0;
}