.pcContent {
  /*flex: 1 0 auto;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pcWrapBG {
  min-height: 100vh;
  background-image: url("./img/bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.pcContent h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #393F61;

  margin-top: 81px;
  margin-bottom: 28px;
}

.fox {
  position: relative;
  display: flex;
  /*flex-direction: column;*/
  justify-content: flex-end;
  min-height: 477px;
  text-align: center;
}

.fox img {
  max-width: 100%;
  position: relative;
  z-index: 1;
  /*left: -28%;*/
}

.foxSay {
  width: 227px;
  min-height: 145px;
  border-radius: 16px;
  background-color: #fff;
  color: var(--color8);
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  position: relative;
  box-shadow: 10px 10px 50px rgba(77, 163, 247, 0.45);
  text-align: left;
  padding: 20px 19px;
}

.foxSay:before {
  content: '';
  width: 30px;
  height: 21px;
  position: absolute;
  bottom: 0;
  left: -14px;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-image: url("../../img/cloud_tail.svg");
  background-color: #fff;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.foxSay .button {
  background-color: var(--primary-orange);
  box-shadow: none;
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
  margin: 5px 0;
  text-align: center;
}

.foxSay a {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #fff;
}

.foxSay .link {
  font-family: 'Manrope';
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #F18825;
  display: block;
}

.foxSay .link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.foxSay .title {
  margin-bottom: 20px;
}

.infoContainer {
  position: absolute;
  z-index: 2;
  left: calc(100% - 20px);
}

.infoContainer p {
  margin-top: 22px;
  margin-left: 19px;
  text-align: left;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #393F61;
}

.infoContainer p a {
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #393F61;
  cursor: pointer;
}

.buttonNext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid var(--color7-a);
  cursor: pointer;
  color: var(--color7-a);
  border-radius: 10px;
  background: #fff;
  transition: all .3s ease-in;
  outline: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 0;
  width: 100%;
  height: 48px;
  margin-bottom: 10px;

  &:hover {
    border-color: var(--color7-b);
    color: var(--color7-b);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &--fill {
    border: none;
    background: var(--color7-a);
    color: #fff;
    //height: 58px;

    &:hover {
      background: var(--color7);
      color: #FFF;
    }
  }

  &--small {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 767px) {

  .pcContent {
    padding-left: 21px;
    padding-right: 21px;
    box-sizing: border-box;
  }

  .pcContent h1 {
    font-size: 32px;
    line-height: 38px;
  }

  .fox {
    min-height: 440px;
  }

  .foxSay {
    text-align: center;
    width: 100%;
  }

  .infoContainer {
    width: 100%;
    top: calc(80%);
    left: 0;
  }

  .foxSay {
    align-self: center;
  }

  .foxSay:before {
    width: 0;
  }

  .infoContainer p {
    text-align: center;
  }
}