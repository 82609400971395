.outerWrapper {
  position: fixed;
  top: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: var(--blue-background);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.wrapper {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(57, 63, 97, 0.4);
  font-family: "Manrope", sans-serif;
  color: var(--color8);
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  max-width: 100%;
  &.short {
    width: 460px;
    height: 314px;
    padding: 43px 70px;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    .textButton {
      margin-top: -5px;
      line-height: 20px;
    }
  }
  &.long {
    width: 875px;
    height: 460px;
  }
}

.generalTitle h2{
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  margin: 0;
}

.title {
  color: var(--color8);
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  &.normal {
    font-size: 18px;
    line-height: 30px;
    font-weight: normal;
  }
  h3 {
    text-align: left;
    margin: 0;
  }
}

.iconsPanel {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: -10px;
}

.iconWrapper {
  width: 40px;
  height: 40px;
}

.icon {
  width: 40px;
  height: 40px;
  & > * {
    fill: var(--color8);
    stroke: var(--color8);
    stroke-width: 0;
  }
}

/*.cameraDisabled path:nth-child(2){
  stroke-width: 3.2;
}*/

.buttonContainer {
  position: relative;
  width: 100%;
}

.button {
  width: 70%;
  height: 58px;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  a {
    display: inline-block;
    width: 100%;
    padding: 0 20px;
    line-height: 58px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    transition: color 0.3s ease;
  }
  &.error {
    background: var(--danger);
    cursor: auto;
    border: none;
  }
}

.button:hover {
  background: transparent;
  a {
    color: var(--primary);
  }
  &.error {
    background: var(--danger);
    cursor: auto;
    border: none;
    a {
      color: #fff;
    }
  }
}

.buttonTooltip {
  position: absolute;
  text-align: center;
  width: calc(100% - 20px);
  bottom: 100%;
  background: #fff;
  color: var(--color8-a);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(57, 63, 97, 0.2)
}

.textButton {
  cursor: pointer;
  color: var(--primary);
}

.videoBlock {
  display: flex;
  align-items: center;
  flex: 3 3;
  background-color: var(--face-bg1);
  background-image: url("img/background_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
  position: relative;
  max-width: 490px;
  overflow: hidden;
  video {
    height: 100%;
  }
  .infoPanel {
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 0;
    width: calc(100% - 40px);
    height: 30px;
    padding: 0 20px;
    .name {
      text-align: center;
      color: #fff;
      line-height: 20px;
      font-weight: bold;
      flex: 1 1;
    }
    .roundIcon {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      &, .icon {
        height: 30px;
        width: 30px;
        & > * {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }
}

.infoBlock {
  display: flex;
  flex: 2 2;
  padding: 30px;
  align-items: center;
  max-width: 345px;
}

.infoBlock .content{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  .button {
    width: 60%;
  }
  .iconWrapper {
    margin-right: 20px;
    &, .icon {
      height: 50px;
      width: 50px;
    }
  }
}

.checkBlock {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkDevice {
  display: flex;
}

.bold {
  color: var(--color8);
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.failed {
  .checkInfo {
    color: #ff0000;
  }
  .icon > *{
    fill: #ff0000;
    stroke: #ff0000;
  }
}

.pending {
  .checkInfo {
    color: var(--color8-a);
  }
  .icon > *{
    fill: var(--color8-a);
    stroke: var(--color8-a);
  }
}

.messageFox {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 240px;
  height: 217px;
  padding-right: 69.5px;
  padding-bottom: 98px;

  .message {
    max-width: 385px;
    min-height: 70px;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);
    background-color: #FFF;
    border-radius: 10px 10px 0 10px;
    .text {
      flex: 1 1;
      color: var(--color8);
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
    }
    .image {
      background-image: url("img/Pic_micro.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 210px;
      height: 85px;
    }
    .closeFox {
      position: absolute;
      top: 10px;
      right: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
 .message::before {
   content: "";
   position: absolute;
   width: 0;
   height: 0;
   right: 0;
   bottom: -20px;
   border-style: solid;
   border-width: 0 20px 20px 0;
   border-color: transparent #ffffff transparent transparent;
   box-shadow: 22px 6px 16px rgb(17 48 127 / 20%);
 }
  .fox {
    background-image: url("img/fox.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    width: 159px;
    height: 90px;
    position: absolute;
    bottom: 0;
    right: -30px;
  }
}

@media only screen and (max-width: 1024px) {
  .messageFox {
    display: none;
  }
}

@media only screen and (max-width: 767px){
  .wrapper {
    position: fixed;
    &.long {
      width: 100%;
      height: 100%;
      flex-direction: column;
      overflow-y: auto;
      .infoBlock {
        padding: 20px 30px;
        .content{
          gap: 20px;
        }
      }
      .title {
        font-size: 18px;
      }
    }
    &.short {
      padding: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
    }
  }
}

@media only screen and (orientation: landscape){
  .wrapper.long{
    flex-direction: row;
  }
}

@media only screen and (max-height: 401px) and (orientation: landscape){
  .wrapper.short{
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}

@media (hover: none) {
  .button:active {
    &+.buttonTooltip {
      display: block;
    }
  }
}