.wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    display: inline-block;
    z-index: 10;
}

.innerWrap {
    overflow: hidden;
    max-height: 0;
    transition: all 0.4s;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.4s;
    border-radius: 50px;
    opacity: 0;
}

.wrap_bottom .innerWrap {
    top: 100%;
}

.inner {
    
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.wrap_dark.open .innerWrap {
    background: rgba(0, 0, 0, 0.8);
    mix-blend-mode: normal;
}

.wrap_light.open .innerWrap {
    background: rgba(255, 255, 255, 0.9);
    mix-blend-mode: normal;
}

.open .innerWrap {
    max-height: 500px;
    max-height: 100vh;
    /* padding: 12px; */
    opacity: 1;
    padding-bottom: 100%;
    mix-blend-mode: normal;
}

.wrap .toggle {
    position: relative;
    z-index: 2;
    cursor: pointer;
    /* padding: 12px; */
}

.open .toggle {
    background: none;
}
