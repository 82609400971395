.btn {
  position: relative;
  height: 40px;
  width: 40px;

  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  display: flex;
  align-content: center;
  justify-content: center;
}

.btn:disabled {
  opacity: 0.5;
}
.btn:active {
  border: none;
}

.btn:visited {
  border: none;
}

.btn:focus {
  border: none;
}

.messagesNotifications {
  position: absolute;
  left: 20px;

  box-sizing: border-box;
  height: 20px;
  max-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 10px;
  border: solid 2px #FFFFFF;

  background-color: #FF0000;

  /*padding-top: 3px;*/
  padding-bottom: 3px;
  padding-right: 5px;
  padding-left: 5px;

  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /*line-height: 157%;*/
  line-height: 133%;

  color: #FFFFFF;
}

.tooltip {
  z-index: 99999999999999 !important;
  max-width: 170px !important;
  font-weight: normal;
  font-size: 14px;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  border-radius: 10px;
  display: flex !important;
  align-items: flex-end !important;
  padding: 12px 21px !important;

}