@import-normalize; /* bring in normalize.css styles */

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&family=Montserrat:wght@400;700&display=swap");

@font-face {
    font-family: "Circe";
    font-weight: 700;
    src: url("./fonts/circe_bold.eot"); /* IE 9 Compatibility Mode */
    src: url("./fonts/circe_bold.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("./fonts/circe_bold.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./fonts/circe_bold.woff")
            format("woff"),
        /* Firefox >= 3.6, any other modern browser */
            url("./fonts/circe_bold.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./fonts/circe_bold.svg#circe_bold")
            format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Circe";
    font-weight: 800;
    src: url("./fonts/circe_extrabold.eot"); /* IE 9 Compatibility Mode */
    src: url("./fonts/circe_extrabold.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("./fonts/circe_extrabold.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./fonts/circe_extrabold.woff")
            format("woff"),
        /* Firefox >= 3.6, any other modern browser */
            url("./fonts/circe_extrabold.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("./fonts/circe_extrabold.svg#circe_extrabold") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Circe";
    font-weight: 200;
    src: url("./fonts/circe_extralight.eot"); /* IE 9 Compatibility Mode */
    src: url("./fonts/circe_extralight.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("./fonts/circe_extralight.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./fonts/circe_extralight.woff")
            format("woff"),
        /* Firefox >= 3.6, any other modern browser */
            url("./fonts/circe_extralight.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("./fonts/circe_extralight.svg#circe_extralight") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Circe";
    font-weight: 300;
    src: url("./fonts/circe_light.eot"); /* IE 9 Compatibility Mode */
    src: url("./fonts/circe_light.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("./fonts/circe_light.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./fonts/circe_light.woff")
            format("woff"),
        /* Firefox >= 3.6, any other modern browser */
            url("./fonts/circe_light.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./fonts/circe_light.svg#circe_light")
            format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Circe";
    font-weight: normal;
    src: url("./fonts/circe.eot"); /* IE 9 Compatibility Mode */
    src: url("./fonts/circe.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("./fonts/circe.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./fonts/circe.woff") format("woff"),
        /* Firefox >= 3.6, any other modern browser */ url("./fonts/circe.ttf")
            format("truetype"),
        /* Safari, Android, iOS */ url("./fonts/circe.svg#circe") format("svg"); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Circe";
    font-weight: 100;
    src: url("./fonts/circe_thin.eot"); /* IE 9 Compatibility Mode */
    src: url("./fonts/circe_thin.eot?#iefix") format("embedded-opentype"),
        /* IE < 9 */ url("./fonts/circe_thin.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./fonts/circe_thin.woff")
            format("woff"),
        /* Firefox >= 3.6, any other modern browser */
            url("./fonts/circe_thin.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./fonts/circe_thin.svg#circe_thin")
            format("svg"); /* Chrome < 4, Legacy iOS */
}

@import "./variables.css";

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;

    /* системные штрифты по дефолту если есть https://htmlacademy.ru/blog/boost/frontend/short-14 */
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    font-family: "Manrope", sans-serif;
}

body {
    user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

button {
    outline: none;
    border: none;
}

button:focus {
    outline: none;
    border: none;
}

#root {
     height: 100%;
}

.local-video {
    max-width: 300px;
    height: 150px;
}

.viewer-video {
    width: 300px;
    height: 150px;
    background: #ebebeb;
}

.remote-video {
    width: 520px;
    height: 260px;
    background-color: #ebebeb;
}

.presenter-error {
    background: red;
    color: white;
    padding: 20px;
}

.viewer-link {
    padding: 10px;
    width: 200px;
}

.call-participants {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mute-button {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #676767b3;

    width: 40px;
    height: 40px;
    /* line-height: 50px; */
    border-radius: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.mute-button:hover {
    background-color: #c40505;
}
.mute-button:active {
    background-color: #aaaaaa;
}

.button-round {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
}

.button-round:active {
    box-shadow: 0px -2px 10px rgba(17, 48, 127, 0.2);
}

.button-round:focus,
.button-round:hover {
    box-shadow: 0px 2px 10px rgba(17, 48, 127, 0.5);
}

/* TODO потом удалить это временное явление */
.presenter-screen {
    display: flex;
    flex-direction: row;
    /* flex-direction: column; */
    /* align-items: center; */
}

.presenter-pupils {
    list-style: none;
    display: block;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* flex-direction: column; */

    width: 100%;
    align-items: flex-start;
}

.presenter-meta {
    display: flex;
    /* flex-direction: row; */
    /* box-shadow: 0 0 5px grey; */
    /* width: 100%; */

    flex-direction: column;
    width: 300px;
    align-items: center;
}

.presenter-meta_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.presenter-local-video {
    max-width: 300px;
    height: 150px;

    position: relative;
}

.sound-setting {
    color: #9e9eff;
    cursor: pointer;
}

.sound-setting.sound-setting__current {
    color: #999;
    cursor: default;
}

.iconColorOn {
    fill: #fff;
    stroke: none;
}

.iconColorOff {
    fill: #ff0000;
    stroke: #ff0000;
}

.log-text-area {
    height: 400px;
    width: 100%;
}

.sample-device {
    border-bottom: 1px solid #ebebeb;
    padding: 10px;
}
.sample-device_id {
    color: #aaa;
    padding-top: 5px;
    font-size: 10px;
}

.promo-screen {
    display: flex;
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.promo-screen_main {
    display: flex;
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: normal;
    justify-content: space-between;
}

.promo-screen_head {
    display: flex;
}

.promo-screen_head__login {
    margin-left: auto;
}

.promo-screen_message {
    text-align: center;
    font-size: 30px;
    color: #393f61;
}

.promo-screen_cta {
    border: 1px solid #f18825;
    box-sizing: border-box;
    border-radius: 10px;
    color: #f18825;
    flex-shrink: 0;
    display: flex;
    text-decoration: none;
    padding: 16px;
    position: relative;
    transition: all 0.2s;
    font-size: 20px;
}
.promo-screen_cta:hover {
    color: #ea5908;
    border-color: #ea5908;
    box-shadow: 0 5px 8px rgba(247, 125, 11, 0.3);
}

[data-title] {
    position: relative;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    color: #4da3f7;
    background: #fff;
    padding: 14px 19px;
    display: inline;
    z-index: 5;

    bottom: 0%;
    left: 0%;

    transition: opacity 0.1s;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    max-width: 320px;
    white-space: normal;
    text-align: left;
}

.tooltip {
    z-index: 5;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    max-width: 220px;
    opacity: 1;
}

.tooltip.tooltip_copy {
    padding: 40px 50px;
    background: #fff;
    box-shadow: 10px 10px 0px rgba(77, 163, 247, 0.27);
    box-sizing: border-box;
    color: #393f61;
    font-family: Core Sans A 45 Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    width: 350px;
    max-width: 100vw;
}

.tooltip_close {
    z-index: 2;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    height: 15px;
    width: 15px;
    flex-shrink: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    background-image: url("./img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.tooltip .tooltip_copy_title {
    font-family: Core Sans A 65 Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    /* or 117% */

    /* P */
}

.tooltip_copy_content {
    max-width: 440px;
}

.cursor-pointer {
    cursor: pointer;
}

[data-title]:hover:after {
    opacity: 1;
    visibility: visible;
    bottom: 100%;
    transform: translateY(-10px);
}

:focus {
    outline-width: 1px;
    outline-style: solid;
    outline-color: #4da3f787;
}

.icon-svg {
    fill: currentColor;
}

.reset-button {
    outline: none;
    padding: 0;
    border: none;
    background: none;
    color: currentColor;
    cursor: pointer;
}

.b-teacher-epub {
    /* height: 100%; */
    overflow-y: scroll;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #000000;

    display: flex;
    align-items:center;

    /* display: flex; */
    /* justify-content: center; */
}

.epub-mark {
    text-decoration: underline red;
}

.char {
    border-bottom: transparent 4px dotted;
    box-sizing: border-box;
}

.b-teacher-epub::-webkit-scrollbar {
   width: 8px;
  height: 8px;
   background-color: transparent;
   cursor: pointer;
 }

.b-teacher-epub::-webkit-scrollbar-thumb {
   border-radius: 4px;
   height: 50%;
  background-color: var(--primary);
   cursor: pointer;
 }

.pupil-epub::-webkit-scrollbar-thumb {
    background-color: var(--primary-orange);
}

.b-teacher-epub_content {
    margin: 0 auto;
    max-width: 768px;
    /*text-decoration: underline dashed transparent 3px;*/

    -webkit-touch-callout: default; /* iOS Safari */
    -webkit-user-select: text;   /* Chrome/Safari/Opera */
    -khtml-user-select: default;    /* Konqueror */
    -moz-user-select: text;      /* Firefox */
    -ms-user-select: text;       /* Internet Explorer/Edge */
    user-select: text;
}

.b-teacher-epub_content *::selection,-moz-selection {
  background: transparent;
  /*text-decoration: underline dashed #4da3f7 3px;*/
}

.b-teacher-epub_content__pupil {
    margin: auto;
    padding: 20px;
}

@media (max-width: 1000px) {
  .b-teacher-epub_content__pupil {
    padding-top: 85px;
  }
}

.icon {
    display: inline-block;
    min-width: 1em;
    min-height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;

    max-width: 60px;
    max-height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    text-align: center;
}

.button {
    cursor: pointer;
}

.button_no-style {
    cursor: pointer;
    padding: 0;
    background: none;
    outline: none;
    padding: 0;
    color: currentColor;
    border: none;
    font-size: inherit;
}

.icon_danger {
    color: var(--danger, #4da3f7);
}

.color_danger {
    color: var(--danger, red);
}

.video-gradient {
    position: absolute;
    left: 0;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    user-select: none;
    z-index: 3;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 82.9%,
        rgba(0, 0, 0, 0.7) 100%
    );
}

.video-gradient ~ .face-item-name {
    /* color: var(--thema-color); */
    color: #fff;
}

.face-item-reaction {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 3;
}

.face-item-reaction .icon {
    max-width: 100%;
    max-height: 100%;
}

.face-item-avatar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.face-item-mute-icon {
    position: absolute;
    right: 2%;
    z-index: 3;
    bottom: 2%;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 0, 0, 0.5);
}

.face-other-count {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    /* or 100% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
}

.face-item-mute-icon .icon {
    width: 15px;
    fill: #fff;
}

.face_speaking:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #4da3f7;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    user-select: none;
}

.icon_hand {
    width: 45px;
    height: 46px;
    background-image: url("./img/hand.svg");
}

.icon_glad {
    width: 45px;
    height: 54px;
    background-image: url("./img/glad.svg");
}

.icon_sad {
    width: 45px;
    height: 54px;
    background-image: url("./img/sad.svg");
}

.icon_alarm {
    width: 45px;
    height: 46px;
    background-image: url("./img/ALARM.svg");
}

.icon_good {
    width: 45px;
    height: 63px;
    background-image: url("./img/good.svg");
}

.icon_hand.icon_dark {
    background-image: url("./img/hand-1.svg");
}

.icon_glad.icon_dark {
    background-image: url("./img/glad-1.svg");
}

.icon_sad.icon_dark {
    background-image: url("./img/sad-1.svg");
}

.icon_alarm.icon_dark {
    background-image: url("./img/ALARM-1.svg");
}

.icon_good.icon_dark {
    background-image: url("./img/good-1.svg");
}

.b-teacher-epub::selection {
    background-color: var(--primary);
}

.b-teacher-epub-with-select *::selection,-moz-selection {
    background: #B4D5FE;
}

.b-teacher-epub img {
    position: initial;
    user-select: initial;
}

.b-teacher-epub .mark {
    background-color: var(--primary);
}

.highlight {
    background-color: #4da3f7;
}

.highlight_presenter {
  background-color: #4da3f7;
}

.highlight_viewer {
    background-color: #b1f6e6;
}

.position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mobile-visible {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .mobile-hidden {
        display: none;
    }
    .mobile-visible {
        display: block;
    }
}

.hide {
    user-select: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -9999px;
}
.display-none {
    display: none;
}

/* class applies to select element itself, not a wrapper element */
.select-css {
    display: block;

    font-family: sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */

    margin: 0;
    background: #ffffff;
    border: 1px solid #b4b4b4;
    box-sizing: border-box;
    border-radius: 2px;
    border-radius: 2px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

	*/
    background-image: url("./img/arrow-down.svg");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
    display: none;
}
/* Hover style */
.select-css:hover {
    border-color: #888;
}
/* Focus style */
.select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

/* Set options to normal weight */
.select-css option {
    font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
    color: graytext;
    background-image: url("./img/arrow-down.svg");
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
    border-color: #aaa;
}

.o-hidden {
    overflow: hidden;
}

.ta-center {
    text-align: center;
}

.custom-check {
    text-align: left;
    display: inline-block;
}

.custom-check label{
    position: relative;
    padding-left: 30px;
    display: inline-block;
}

.custom-check label:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    background: rgba(232, 120, 140, 0.19);
    border: 2px solid #e8788c;
    box-sizing: border-box;
    border-radius: 4px;
    width: 18px;
    height: 18px;
}
.custom-check input {
    position: absolute;
    top: -9999px;
    visibility: hidden;
}

.custom-check input:checked + label:before {
    border-color: transparent;
    background-image: url('./img/check.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.link {
    color: #f18825;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}


.range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--primary)
}

.rotate-180 {
    transform: rotate(180deg);
}


.loader {
    opacity: 1;
    width: 40px;
    height: 40px;
    border: 2px solid #f4f8fb;
    border-top: 3px solid #f18825;
    border-radius: 100%;
    animation: spin 0.8s infinite linear;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.emoji {
    font-family: Apple Color Emoji,Segoe UI Emoji,Noto Color Emoji,Android Emoji,EmojiSymbols,EmojiOne Mozilla,Twemoji Mozilla,Segoe UI Symbol,Noto Color Emoji Compat,emoji,noto-emojipedia-fallback !important;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.height-auto {
    height: auto;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.flex-grow {
    flex-grow: 1;
    width: 100%;
}
.ta-canter {
    text-align: center;
}



@media only screen and (max-width: 699px) {
    .b-offer h1 {
        font-size: 24px;
    }
    .b-offer h2 {
        font-size: 20px;
    }
    .b-offer ol {
        padding-left: 15px;
    }
    .b-offer li {
        padding-left: 15px;
    }
}
