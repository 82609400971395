.fixedWrapper
  min-width: 80px
  height: 100vh
  min-height: 680px
  display: block


.sidebarWrapper
  position: fixed
  width: 85px
  height: 100%
  top: 0

.sidebar
  background: var(--sidebar-background)
  border-right: 1px solid var(--border)
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 30px
  padding-bottom: 30px
  box-sizing: border-box
  justify-content: space-between
  position: fixed
  z-index: 5

  ul
    list-style: none
    margin: 0
    padding: 0

    li
      margin-bottom: 20px

  .group
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    .timer
      color: var(--color8)
      font-weight: 700
      font-size: 14px
      margin-bottom: 20px
      height: 15px
      width: 60px


.noFix
  position: relative