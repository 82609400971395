.wrap {
    /*width: 100%;*/
    background: #000;
    position: absolute;
    box-sizing: border-box;
}

.audio {
    height: 0;
  margin-left: 130px;
}

.isStart {
    padding-bottom: 15px;
    z-index: 3;
}

.isStart {
    height: 100%;
}

.controls {
    position: absolute;
    left: 10px;
    top: 20px;
    z-index: 3;
}

.button {
    cursor: pointer;
}