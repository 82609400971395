.curtainMenuWrap
  position: relative
  margin-bottom: 10px

  .modeOptionBtn
    background: none
    border: none
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    position: relative

    &:focus,
    &:active
      outline: none

    &:disabled
      opacity: 0.5

    &.selected
      background: var(--primary)
      border-radius: 2px

    .angle
      position: absolute
      bottom: 3px
      right: 3px

  .dropdownChildren
    margin: 0
    padding: 0
    list-style: none
    position: absolute
    background: var(--color5-c)
    box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2)
    border-radius: 6px
    color: var(--primary)
    line-height: 20px
    font-size: 16px
    display: block
    min-width: 240px
    font-weight: bold
    z-index: 10

    &.hide
      display: none
    &.top
      bottom: 100%
    .child
      cursor: pointer
      box-sizing: border-box
      padding: 10px
      display: flex
      align-items: center

      &:first-of-type
        border-top-left-radius: 6px
        border-top-right-radius: 6px

      &:last-of-type
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px

      .childIcon
        height: 25px
        width: 25px
        margin-right: 10px

      .childIconLeft
        background: url(./img/left-right.svg) no-repeat

      .childIconRight
        background: url(./img/right-left.svg) no-repeat

      .childIconTop
        background: url(./img/top-bottom.svg) no-repeat

      .childIconBottom
        background: url(./img/bottom-top.svg) no-repeat

      .closeCurtain
        position: relative
        &:before
          position: absolute
          content: ''
          width: 26px
          height: 2px
          background: var(--primary)
          transform: rotate(45deg)
          border-radius: 5px
          top: 10px
        &:after
          position: absolute
          content: ''
          width: 26px
          height: 2px
          background: var(--primary)
          transform: rotate(-45deg)
          top: 10px
          border-radius: 5px

      &:hover
        background: var(--primary)
        color: #fff

        .childIconLeft
          background: url(./img/left-right-light.svg) no-repeat

        .childIconRight
          background: url(./img/right-left-light.svg) no-repeat

        .childIconTop
          background: url(./img/top-bottom-light.svg) no-repeat

        .childIconBottom
          background: url(./img/bottom-top-light.svg) no-repeat

        .closeCurtain
          &:before
            background: #FFF
          &:after
            background: #FFF
