.btn {
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #393F61;

}

.activeBtn {
    color: #4DA3F7;
}

.contentContainer {
    display: flex;
    gap: 30px;
    align-items: center;
}

.titleAndDescriptionContainer {
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 133%;

    text-align: left;
    word-wrap: break-word;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    text-align: left;
    word-wrap: break-word;
}