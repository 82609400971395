.wrap {
    background: #393f61;
    opacity: 0.9;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    /* or 187% */

    /* W */

    color: #ffffff;

    translate: all 0.4s;
    position: fixed;
    right: 0;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    top: -100%;
    opacity: 0;
}

.offline {
    top: 0;
    opacity: 1;
    z-index: 100;
}

.inner {
    display: flex;
    align-items: center;
}

.txt {
    margin-left: 20px;
}

.icon {
    flex-shrink: 0;
}