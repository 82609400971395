.top {
    align-items: center;
    justify-content: space-between;
    margin: 22px 0 10px;
    display: none;
}

.left {
    display: flex;
    align-items: center;
}
.slogan {
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    display: flex;
    align-items: center;

    /* #252A2C */

    color: #393f61;
    opacity: 0.5;
}

.logo {
    margin: 0px 20px;
}

.tel {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: right;

    /* #252A2C */

    color: #393f61;
    margin: 9px 16px 0;
    text-decoration: none;
}

.bottom {
    background: #ffffff;
    box-shadow: 0px 15px 40px rgba(166, 207, 228, 0.62);
    border-radius: 14px;
    display: flex;
    padding: 18px 18px 21px;
    align-items: center;
}

.bottom_left {
}

.bottom_right {
    margin-left: auto;
}

.bottom_txt {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
}

.bottom_txtH2 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
}

.bottom_txtIq {
    font-weight: bold;
    font-size: 28px;
    line-height: 23px;
    /* identical to box height, or 84% */

    display: inline-block;
    vertical-align: middle;

    margin-right: 40px;
    margin-left: 8px;
    letter-spacing: -0.03em;
    /* max-width: 165px; */
    color: #ed612e;
}

.buttonSide {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    position: relative;
    width: 21px;
    height: 21px;
    cursor: pointer;
    /* z-index: 10; */
}

.buttonSideLine1,
.buttonSideLine2 {
    left: 50%;
    margin-left: -10px;
    width: 21px;
    height: 21px;
    position: absolute;
    background: #f18825;
    height: 2px;
    border-radius: 2px;
    transition: all 0.4s;
}

.buttonSideLine1 {
    top: 5px;
}

.buttonSideLine2 {
    bottom: 5px;
}

.buttonSide.close {
    outline: none;
    /* z-index: 11; */
}

/* .buttonSide.close .buttonSideLine1 {
    transform: rotate(45deg);
    top: 50%;
    left: 0%;
    margin: 0;
}

.buttonSide.close .buttonSideLine2 {
    transform: rotate(-45deg);
    top: 50%;
    left: 0%;
    margin: 0;
} */

@media (max-width: 1060px) {
    .bottom_txtIq {
        margin-right: 10px;
    }
}

@media (min-width: 1023px) {
    .top {
        display: flex;
    }
}

@media only screen and (max-width: 1023px) {
    .bottom_txtIq {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        /* identical to box height, or 117% */

        letter-spacing: -0.03em;
    }
    .bottom {
        margin: 10px auto;
        padding: 10px;
    }
    .bottom_right button {
        max-width: 158px;
        opacity: 1;
        width: 158px;
        visibility: visible;
        margin-left: 38px;
        flex-shrink: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        width: 124px;
        height: 42px;
        min-height: auto;
        margin: 0 0 0 auto;
        padding: 10px;
        min-width: auto;
    }
}
