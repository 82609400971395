.checkboxWrap
  display: flex
  align-items: center
  height: 30px
  box-sizing: border-box

  .checkbox:checked,
  .checkbox:not(:checked)
    display: none

  .checkbox:checked + .checkboxLabel,
  .checkbox:not(:checked) + .checkboxLabel
    display: inline-block
    position: relative
    padding-left: 30px
    cursor: pointer

  .checkbox:checked + .checkboxLabel:before,
  .checkbox:not(:checked) + .checkboxLabel:before
    content: ""
    position: absolute
    left: 0

  .checkbox:checked + .checkboxLabel:before
    width: 18px
    height: 18px
    background-color: var(--primary)
    border: 2px solid var(--primary)

  .checkbox:not(:checked) + .checkboxLabel:before
    width: 18px
    height: 18px
    border: 2px solid var(--color8-a)

  .checkbox:checked + .checkboxLabel:before,
  .checkbox:not(:checked) + .checkboxLabel:before
    border-radius: 3px

  .checkbox:checked + .checkboxLabel:after,
  .checkbox:not(:checked) + .checkboxLabel:after
    content: ""
    position: absolute
    -webkit-transition: all 0.2s ease
    -moz-transition: all 0.2s ease
    -o-transition: all 0.2s ease
    transition: all 0.2s ease

  .checkbox:checked + .checkboxLabel:after,
  .checkbox:not(:checked) + .checkboxLabel:after
    left: 6px
    top: 6px
    width: 8px
    height: 4px
    border-left: 3px solid #fff
    border-bottom: 3px solid #fff
    -webkit-transform: rotate(-45deg)
    -moz-transform: rotate(-45deg)
    -o-transform: rotate(-45deg)
    -ms-transform: rotate(-45deg)
    transform: rotate(-45deg)

  .checkbox:not(:checked) + .checkboxLabel:after
    opacity: 0

  .checkbox:checked + .checkboxLabel:after
    opacity: 1