

.wrap {
    width: 82px;
    /* background: var(--thema-bg); */
    border-radius: 60px;
    transition: all 0.4s;
    position: relative;
    height: 87px;
    flex-shrink: 0;
    bottom: 10px;
    
    
}

.isOpen .wrapInner {
    max-height: 500px;
    height: 100vh;
}

.isOpen .button {
    background: none !important;
}

.wrapInner {
    height: 87px;
    max-height: 87px;
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    transition: all 0.4s;
}
.inner {
    padding: 25px 0 0px;
    /* background: var(--thema-bg); */
    border-radius: 50px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.wrap_dark.isOpen .inner{
    background: rgba(0, 0, 0, 0.8);
    mix-blend-mode: normal;
}

.wrap_light.isOpen .inner{
    background: rgba(255, 255, 255, 0.9);
    mix-blend-mode: normal;
}

.button {
    border-radius: 50%;
    outline: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    width: 72px;
    height: 72px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    /* border: 4px solid #dbedfd; */
    box-sizing: content-box;
    position: relative;
}

.handUpWrap {
    width: 100%;
    height: 100%;
    /* border: 7px solid #fff; */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.handUp {
    position: relative;
    left: 2px;
}

.handUpYes {
    fill: #65b016;
}

.buttonDoneNeedHelp .handUp {
}

.ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    pointer-events: none;
}

.icon {
    display: block;
    max-width: 60px;
    max-height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    text-align: center;

}

.btnTxt {
    display: block;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 15px;
}



@media only screen and (max-width: 1023px) {
    
}