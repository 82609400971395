.curtain {
    position: absolute;
    top: 0;
    max-height: 100%;
    max-width: 100%;
    z-index: 1;
    cursor: pointer;
    transition: all 0.4s;
    background: linear-gradient(0deg, rgba(77, 163, 247, 0.1), rgba(77, 163, 247, 0.1)), #FFFFFF;
    visibility: hidden;
    opacity: 0;
    user-select: none;
}

.isShow {
    visibility: visible;
    opacity: 1;
    /*user-select: none;*/
}

.curtain_top, .curtain_bottom {
    left: 0;
    width: 100%;
    height: 0%;
}



.curtain_top:before {
    content:'';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: block;
    height: 4px;
    background: var(--primary);
    margin: 0 -3px;
}

.curtain_top:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: 100%;
    right: 0;
    margin-top: -5px;
    margin-right: -7px;
    background: var(--primary);
    border-radius: 50%;
}


.curtain_right:before {
    content:'';
    position: absolute;
    top: 0%;
    bottom: 0;
    left: 0;
    height: 100%;
    display: block;
    width: 4px;
    background: var(--primary);
    margin: -3px 0;
}

.curtain_right:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: 100%;
    left: 0%;
    margin-top: -7px;
    margin-left: -5px;
    background: var(--primary);
    border-radius: 50%;
}

.curtain_left:before {
    content:'';
    position: absolute;
    top: 0%;
    bottom: 0;
    right: 0;
    height: 100%;
    display: block;
    width: 4px;
    background: var(--primary);
    margin: 0;
}

.curtain_left:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: 100%;
    right: 0%;
    margin-top: -7px;
    margin-right: -5px;
    background: var(--primary);
    border-radius: 50%;
}

.curtain_bottom:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 4px;
  background: var(--primary);
  margin: 0 -3px;
}

.curtain_bottom:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -7px;
  background: var(--primary);
  border-radius: 50%;
}

.curtain_top:before, .curtain_top:after, .curtain_right:before, .curtain_right:after {
    opacity: 0;
    visibility: hidden;
    user-select: none !important;
}

.isShow:before, .isShow:after {
    opacity: 1;
    visibility: visible;
    /*user-select: none;*/
}

.isShow.curtain_top, .isShow.curtain_bottom {
    height: 20%;
}

.curtain_bottom {
    top:auto;
    bottom: 0;

}

.curtain_left, .curtain_right {
    left: 0;
    height: 100%;
    width: 0;
}

.isShow.curtain_left, .isShow.curtain_right {
    width: 20%;
}

.curtain_right {
    left: auto;
    right: 0;
}

