:root {
  /* COLORS */
  /*
    ЦВЕТА

  */
  --primary: #4DA3F7;
  --danger: #FF0000;
  --burgundy: #C40000;
  --table-item: rgba(229, 246, 255, .4);
  --border: #EAEEF1;
  --primary-orange: #F18825;
  --sidebar-background: #F8FBFF;

  /* colors test page */
    --blue-background: #E9F0FA;
;
    --succeed: #65B016;

  /* COLORS GROUPS*/
    --color1: #7800E6;
    --color1-a: #E4CCFA;

    --color2: #F15700;
    --color2-a: #FCDDCC;

    --color3: #FFBB00;
    --color3-a: #FFF1CC;

    --color4: #87D20C;
    --color4-a: #E7F6CE;

    /* запасные группы */
    --color5: #25ade9;
    --color5-a: #25ade933;
    --color5-b: #3381DC;
    --color5-c: #DEF3FC;

    --color6: #ffc121;
    --color6-a: #ffc12133;

    --color7: #E36A13;
    --color7-a: #F18825;
    --color7-b: #DC5306;

    --color8: #393F61;
    --color8-a: #B4B4B4;
    --color8-b: #F2F2F2;


  /* END COLORS GROUPS*/
  /* COLORS FACE ITEM */
  --face-bg1: #ebd9f7;
  --face-bg2: #ffebc4;
  --face-bg3: #f9d3d3;
  --face-bg4: #b1f6e6;
  --face-bg5: #d9e9ff;

  /* THEMA LIGHT DEFAULT*/
  --thema-color: #393F61;
  --thema-bg: #DDF3FC;
  /* END THEMA */
  --thema-color-dark: #CED8EC;

    --vh-mobile: 1vh;
}

.thema-light {
  --thema-color: #393F61;
  --thema-bg: #DDF3FC;
}

.thema-dark {
  --thema-color: #CED8EC;
  --thema-bg: #000;
}
