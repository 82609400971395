.wrap {
  display: block;
  position: relative;
}

.input {
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 28px;
  color: var(--color8);
  border: 2px solid transparent;
  position: relative;
  height: 50px;
  padding: 10px 20px;
  border-radius: 10px;
  background: var(--color8-b);

  &.fill {
    background: #fff;
    border: 2px solid var(--border);
  }

  &:focus {
    border-color: var(--primary);
    outline: none;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type=number] {
    padding-right: 30px;
  }
}

.numberArrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.button {
  flex-grow: 2;
  width: 25px;
}
