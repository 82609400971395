.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;

    object-fit: contain;

    height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: #DDF3FC;
}

.hide {
    display: none;
}