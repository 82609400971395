.wrap {
    position: absolute;
    right: 60px;
    bottom: 20px;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy {
    width: 100%;
    height: 100%;
    background-image: url('./img/copy.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    z-index: 2;
    cursor: pointer;
}




@media only screen and (max-width: 1024px) {
    .wrap {
        right: 20px;
        z-index: 10;
    }
}

.wrapNoZindex {
    z-index: inherit;
}