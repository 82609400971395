.other-pupils {
  position: relative;
  font-size: 10px;
}
.other-pupil_audio {
  display: none;
}
.other-pupil_audio.other-pupil_audio__debug {
  display: block;
  margin-right: -300px;
}

.other-pupil_avatar {
  background: #f8ad07;
  font-family: "Circe";
  font-weight: bold;
  font-style: normal;
  font-size: 23.4266px;
  line-height: 23px;
  text-transform: uppercase;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transition-delay: 3s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.other-pupil_avatar__isSpeaking {
  opacity: 1;
  visibility: visible;
  position: relative;
  transition-delay: 0s;
}

.other-pupil_avatar-txt {
  text-shadow: 1px 1px #000
}
