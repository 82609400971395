.gradeModalTitle
  font-family: Manrope, serif
  font-style: normal
  font-weight: bold
  font-size: 14px
  line-height: 20px
  color: var(--color8)

.gradeModalBinary
  text-align: center

.gradeButton
  font-family: Manrope, serif
  font-style: normal
  background: var(--primary)
  border: 2px solid var(--primary)
  color: white
  width: 100%
  cursor: pointer
  user-select: none
  font-size: 14px
  line-height: 24px
  padding: 10px
  border-radius: 10px
  font-weight: 700
  transition: color 0.3s ease-in, background-color 0.3s ease-in

.gradeButton:hover
  background: transparent
  color: var(--primary)

.gradeModalContent
  height: 100%
  display: flex
  flex-direction: column
  box-sizing: border-box
  row-gap: 10px

.gradeModalRow
  margin: 0 -10px 25px

.gradeModalRow:after
  content: ''
  display: table
  clear: both
  width: 100%

.gradeModalCol
  width: 50%
  padding: 0 10px
  display: inline-block
  vertical-align: middle
  box-sizing: border-box

.gradeModalInputMax
  font-family: Manrope, serif
  font-style: normal
  padding-left: 12px
  color: black

.gradeModalLabel
  font-family: Manrope, serif
  font-style: normal
  display: block
  margin-bottom: 10px
  color: black

.gradeModalInput
  border: 1px solid var(--primary)
  color: var(--primary)
  min-width: 120px
  line-height: 30px

//.gradeModalSection
//padding: 20px;