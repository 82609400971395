.button
  position: relative
  cursor: pointer

  .counter
    position: absolute
    width: 20px
    height: 20px
    border-radius: 10px
    background-color: red
    top: -12px
    right: -12px
    font-weight: 700
    font-size: 13px
    font-family: Manrope
    text-align: center
    line-height: 20px
    color: #fff

  .messageРopup
    display: flex
    position: absolute
    margin-top: -38px

    .messageРopupArrow
      width: 0
      height: 0
      border-top: 10px solid transparent
      border-bottom: 10px solid transparent
      border-right: 10px solid #eeeded
      margin-left: 45px

    .messageРopupText
      //position: absolute
      width: 170px
      height: 36px
      background: #FFFFFF
      box-shadow: 0px 2px 10px rgba(17, 48, 127, 0.2)
      font-size: 13px
      line-height: 18px
      color: #393F61
      font-weight: 400
      font-family: Manrope
      margin-top: -20px
      padding: 13px 16px 16px 15px
      border-radius: 10px

      span
        font-weight: 700

.wrapWidget
  display: flex
  position: relative
  z-index: 40

  .pointer
    cursor: pointer

  .mb20px
    margin-bottom: 20px

  .mb35px
    margin-bottom: 35px

  .radioBlock
    font-size: 18px

  .paragraph
    width: 500px
    font-size: 14px
    line-height: 24px

  .paragraphCenterText
    font-family: 'Manrope'
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 30px
    text-align: center
    color: #393F61

  .imgContainer
    display: flex
    gap: 30px
    justify-content: center

  .buttonContainer
    margin-top: 40px
    display: flex
    flex-direction: column
    align-items: center

.buttonLesson
  top: 0
  left: 0
  border: none
  //padding: 5px 6px 4px 5px
  padding: 0
  height: 40px
  width: 40px
  background-color: transparent
  cursor: pointer


.startButton
  background: url("../img/Start_Default.svg") no-repeat center

.startButton:hover
  background: url("../img/Start_Hover.svg") no-repeat center

.stopLesson
  background: url("../img/Stop_Default.svg") no-repeat center

.stopLesson:hover
  background: url("../img/Stop_Hover.svg") no-repeat center
