.slide-content {
  font-family: Manrope, Arial;
  /* font-family: Manrope; */
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
}

.slide-content .slide-header-1 {
  /* font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #1566b7; */

  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  color: #1566B7;
}

.slide-content .slide-header-2 {
  /* font-size: 22px;
  font-weight: bold;
  text-align: center; */
  /* color: #f15700; */

  font-family: Manrope, Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
}

.slide-content .font-small {
  font-size: 14px;
}

.slide-content .font-medium {
  font-size: 18px;
}

.slide-content .font-large {
  font-size: 22px;
}

.slide-content p.font-large {
  text-indent: 20px;
}