.button {
  position: relative;
  cursor: pointer;
  &.light {
    .icon > *{
      fill: var(--color8);
    }
  }
  & .disabled {
    opacity: 30%;
  }
}