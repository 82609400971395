.presenterPupil {
  background: var(--color5-c);
  box-sizing: border-box;
  width: 188px;
  margin: auto;
  outline: 4px solid transparent;
  border-radius: 6px;
  color: var(--primary);
  transition: outline 0.4s;
  transition-delay: 2.5s;
}

.videoWrap {
  width: 100%;
  height: 114px;
  object-fit: contain;
  position: relative;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.videoWrap:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 65.18%,
          rgba(0, 0, 0, 0.4) 100%
  ),
  linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 37.5%);
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000;
}

.audio {
  display: none;
}

.videoWrapControls {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.presenterPupilSpeaking {
  outline: 4px solid currentColor !important;
  transition-delay: 0s;
}

.gradeModal {
  height: 100%;
  width: 100%;
  background: #ddf3fc;
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px;
  bottom: 0;
  box-sizing: border-box;
  z-index: 100;
}

.gradeButton {
  font-family: Manrope, serif;
  font-style: normal;
  background: var(--primary);
  border: 2px solid var(--primary);
  color: white;
  width: 100%;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 24px;
  padding: 8px;
  border-radius: 10px;
  font-weight: 700;
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
}

.gradeButton:hover {
  background: transparent;
  color: var(--primary);
}

.name {
  margin: auto;
  display: block;
  top: 0;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px;
  line-height: 16px;
  padding: 5px 10px;
  box-sizing: border-box;
  background-position: center bottom;
  background-size: cover;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  z-index: 4;
  position: relative;
  color: #fff;
  background-repeat: no-repeat;
}

.needHelpIcon {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  z-index: 3;
  width: 40px;
  flex-wrap: wrap;
}

.helpIconYes {
  background: #65b016;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.needHelpIconActiveYes {
  background: #65b016;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpIconNo {
  background: #ff0000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  opacity: 0;
  visibility: hidden;
}

.needHelpIconInner {
  opacity: 0;
  position: relative;
  left: 0px;
  transition: bottom 0.3s ease 0s;
  bottom: -20px;
  transition: all 0.3s 0s;
}

.needHelpIcon .needHelpIconInner {
  max-width: 35px;
  max-height: 35px;
}

.needHelpIconActive .needHelpIconInner,
.needHelpIconActive .helpIconYes,
.needHelpIconActive .helpIconNo {
  opacity: 1;
  bottom: 0px;
  visibility: visible;
}

.slot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

button.slot {
  cursor: pointer;
}

.muteSlot {
  position: absolute;
  right: 3px;
  top: 5px;
  z-index: 4;
}

.muteChatSlot {
  position: absolute;
  right: 3px;
  top: 35px;
  z-index: 4;
}

/* .timerValue {
    position: absolute;
    left: 10px;
    top: 30px;
    z-index: 4;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
} */

.timerWithButtons {
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  bottom: 0;
  white-space: nowrap;
  z-index: 2;
  left: 0;
  right: 0;
}

.goalSlot {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.directSlot {
  position: absolute;
  right: 10px;
  bottom: 10px;
}


.taskContainerWrap {
  position: relative;
  padding: 25px 10px 10px;
}

.taskContainer {
  min-height: 200px;
  position: relative;
  margin-bottom: 10px;
}

.task {
  width: 100%;
  max-height: 100%;
  height: 200px;
  /*min-height: 200px;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #def3fc;
  position: relative;
}

.taskLarge {
  position: absolute;
  width: 600px;
  z-index: 10;
}

.taskLarge img {
  width: 100%;
  object-fit: contain;
}

.timerButtonPlus,
.timerButtonMinus {
  border: none;
  cursor: pointer;
  width: 55px;
  padding: 5px 5px 7px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  background-color: initial;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.timerButtonPlus:hover,
.timerButtonMinus:hover {
  opacity: 0.85;
}

.timerButtonPlus {
  /*background-image: url("./img/bgButton.svg");*/
  color: #fff;
  background: #4DA3F7;
  border-radius: 6px 0 0 0;
}

.timerButtonMinus {
  /*background-image: url("./img/bgButtonDanger.svg");*/
  color: #fff;
  background: #FF0000;
  border-radius: 0 6px 0 0;
}

.vectorSelectSlot {
  color: #4da3f7;
  position: relative;
  /* background: #fff; */
  display: flex;
  align-items: flex-start;
}

.selectTask {
  max-width: 160px;
  flex-grow: 2;
  /* transition: all 0.4s;
  transition-delay: 2.5s; */
}

.selectTask * {
  font-size: 12px!important;
}

.selectTask .select-task-wrap {
  padding: 7px 0;
}

.vectorSelectSlot:before {
  content: "";
  /* transition: all 0.4s;
  transition-delay: 2.5s; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: currentColor;
  opacity: 0;
}

.presenterPupilSpeaking .vectorSelectSlot:before {
  opacity: 1;
  transition-delay: 0s;
}

.tag_1 {
  background: var(--color1-a);
  color: var(--color1);
}

.tag_1 .vectorSelectSlot {
  color: var(--color1);
}

.tag_1.tag_selected {
  background: var(--color1);
  color: var(--color1);
  outline: 4px solid var(--color1-a);
}

.tag_2 {
  background: var(--color2-a);
  color: var(--color2);
}

.tag_2 .vectorSelectSlot {
  color: var(--color2);
}

.tag_2.tag_selected {
  background: var(--color2);
  color: var(--color2);
  outline: 4px solid var(--color2-a);
}

.tag_3 {
  background: var(--color3-a);
  color: var(--color3);
}

.tag_3 .vectorSelectSlot {
  color: var(--color3);
}

.tag_3.tag_selected {
  background: var(--color3);
  color: var(--color3);
  outline: 4px solid var(--color3-a);
}

.tag_4 {
  background: var(--color4-a);
  color: var(--color4);
}

.tag_4 .vectorSelectSlot {
  color: var(--color4);
}

.tag_4.tag_selected {
  background: var(--color4);
  color: var(--color4);
  outline: 4px solid var(--color4-a);
}

.tag_5 {
  background: var(--color5-a);
  color: var(--color5);
}

.tag_5 .vectorSelectSlot {
  color: var(--color5);
}

.tag_5.tag_selected {
  background: var(--color5);
  color: var(--color5);
  outline: 4px solid var(--color5-a);
}

.tag_6 {
  background: var(--color6-a);
  color: var(--color6);
}

.tag_6 .vectorSelectSlot {
  color: var(--color6);
}

.tag_6.tag_selected {
  background: var(--color6);
  color: var(--color6);
  outline: 4px solid var(--color6-a);
}

.tag_selected .timerSlot,
.tag_selected .selectTask,
.vectorSelectSlotSpeak .timerSlot {
  color: #fff;
  transition-delay: 0s;
}

.tag_selected .vectorSelectSlot .selectTask {
  color: currentColor;
}

.selectTaskSpeak {
  transition-delay: 0s;
}

.taskVideoControls {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}

.taskVideoControls button {
  cursor: pointer;
}

.timerSlot {
  color: #fff;
  flex-shrink: 2;
  width: 73px;
  box-sizing: border-box;
  text-align: right;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  padding: 13px 5px 5px 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  margin-left: 0;
  /* transition: all 0.4s;
  transition-delay: 2.5s; */
}

.questSlot {
  flex-shrink: 1;
  box-sizing: border-box;
  color: #4da3f7;
  display: flex;
  margin-left: auto;
  margin-top: 7px;
  margin-right: 5px;
  position: relative;
  z-index: 2;
  /*right: 0;*/
  /* transition: all 0.4s;
  transition-delay: 2.5s; */
  cursor: pointer;
}

.helpText {
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 10;
  padding: 10px;
  width: 350px;
  border: 1px solid #000;
  background: #fff;
}

.timerSlotDanger {
  color: #ff0000;
}

.timerValue {
  margin-right: 5px;
}

.slidesWrap {
  width: 124px;
  overflow: hidden;
  position: relative;
}

.timerLine {
  position: absolute;
  top: -4px;
  height: 4px;
  left: 0;
  transition: width 0.4s, background 1s;
  background: #4da3f7;
  max-width: 100%;
}

/* .presenterPupilSpeaking .timerLine {
    background: #fff;
} */

.timerLineDanger {
  background: #ff0000 !important;
}

.isTimerFinish {
  background: #ff0000;
  color: #fff;
}

.isTimerFinish .timerSlotDanger {
  color: #fff;
}

.tooltip {
  box-shadow: 0 2px 10px 0 #11307F32;
  opacity: 1!important;
  padding: 8px!important;
}