/*.chatWrapper.whiteTheme {
  --chat-border: #EAEEF1;
  --system-msg: gainsboro;
  --text-color: #393F61;
  --default-color: #B4B4B4;
  --background-color: #fff;
}

.chatWrapper.darkTheme {
  --chat-border: #7A839C;
  --system-msg: gainsboro;
  --text-color: #FFF;
  --default-color: #B4B4B4;
  --background-color: #000;
}*/
.floatChatWindow {
    position: absolute;
    bottom: 150px;
    right: 40px;
    height: 400px;
    width: 350px;
    background-color: white;
    z-index: 100;
}

.chatWrapper {
  border: none;
}

.sendButton {
  margin: 10px;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.chatWrapper.gallery{
  margin: -10px 0 -5px;
}

.chatWrapper.mosaic, .chatWrapper.report{
  margin: -10px -50px -5px 0;
}

.chatWrapper.report{
  z-index: 20;
}

.chatBody {
  height: calc(100% - 140px);
  color: var(--default-color);
  padding: 10px 25px 10px 10px;
  gap: 10px;
  overflow: auto;
}

.systemMessage {
  color: var(--system-msg);
}

.chatInput {
  width: 245px
}

.chatControls {
  border-top: none;
  height: 85px;
  padding: 0 10px;
  margin-bottom: 5px;
  align-items: flex-start;
}

.chatControls textarea {
  font-family: Manrope, sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.chatControls textarea:placeholder-shown {
  line-height: 20px;
}

.lightTheme .chatControls textarea:placeholder-shown {
  color: #7A839C;
}

.lightTheme .sendButton {
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-image: url("../img/send_normal.svg");
  background-color: var(--color8);
  background-image: none;
}

.lightTheme .sendButton:hover {
  background-color: var(--primary);
}

.mobileView {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 20;
}

.mobileView.gallery, .mobileView.mosaic, .mobileView.report {
  margin: 0;
}

.mobileView .closeBlock {
  display: none;
}

.mobileView .chatBody {
  gap: 20px;
}

.mobileHeader {
  display: none;
}

.mobileHeader .backIcon {
  width: 10px;
  height: 20px;
  background-image: url("../img/page_back_arrow.svg");
  /*background-position: center;*/
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  padding-right: 21px;
}

.mobileView .mobileHeader {
  display: flex;
  height: 26px;
  /*border-bottom: 1px solid var(--border);*/
  padding: 19px 63px 21px 21px;
  align-items: center;
  /*justify-content: space-between;*/
}

.mobileView .chatBody {
  height: calc(100% - 148px);
}

.mobileView .chatControls {
  border-top: 1px solid var(--border);
  min-height: 60px;
  height: 60px;
  margin-bottom: 0;
  padding: 0 17px;
  justify-content: space-between;
  align-items: center;
}

.mobileView .chatControls textarea {
  color: var(--color8);
}

.mobileView .chatControls textarea {
  color: var(--color8);
  flex: 1 1;
  /*width: calc(100% - 50px);*/
}

.mobileView .chatControls textarea:focus {
  line-height: 20px;
}

.mobileView .chatControls textarea:placeholder-shown{
  line-height: 40px;
  color: var(--color8-a);
}