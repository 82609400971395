.control {
    display: inline-block;
    margin: 0 2px;
}

.menuControl {

}

.controlIcon {
    width: 26px;
    height: 26px;
}