.button {
  position: relative;
  cursor: pointer;

  .counter {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: red;
    top: -12px;
    right: -12px;
    font-weight: 700;
    font-size: 13px;
    font-family: Manrope;
    text-align: center;
    line-height: 20px;
  }
  &.light {
    .icon > *{
      fill: var(--color8);
    }
    .counter {
      color: #fff;
    }
  }
}