@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.pageWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  min-height: 55px;
  margin-bottom: 40px;
}

.error {
  margin: -2em 0 1em;
  max-width: 280px;
}

.headTitle {
  font-weight: bold;
  /* font-size: 50px; */
  font-size: 20px;
  line-height: 28px;
  /* or 128% */
  margin: 0;
  color: #393f61;
}

.presenterWrap {
  display: flex;
  flex: 1;
}

.presenterContent {
  height: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  /* max-width: 1280px; */
  padding: 20px;
  box-sizing: border-box;
  margin: 10px auto;
  width: 100%;
}

/*.presenterWrap.chatOpen .presenterContent {*/
/*  margin: 30px 0 ;*/
/*}*/

.plashkaContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    height: 100%;
    max-width: 100%;
  }
}

.bottomInfo {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
}

.phoneNumber {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  color: var(--color8);
}

.presenterLeft {
  width: 290px;
  float: left;
  flex-shrink: 0;
  position: relative;
  //z-index: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.presenterRight {
  padding-bottom: 50px;
  flex-grow: 2;
  position: relative;
  z-index: 1;
}

.presenterMeta {
  display: flex;
  flex-direction: column;
  width: 290px;
}

.presenterVideo {
  position: relative;
  display: inline-block;
  height: 150px;
  border-radius: 6px;
  overflow: hidden;
  &.mirrored {
    video {
      -o-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.presenterVideoSetting {
  position: absolute;
  color: #fff;
  top: 10px;
  left: 10px;
  z-index: 2;
}

.presenterVideo video {
  //width: 100%;
  height: 100%;
  object-fit: cover;
}

.mute {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.presenterPupils {
  padding: 0;
  margin: 0 -16px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.presenterPupilsItem {
  margin: 0 16px 20px;
  width: 280px;
  position: relative;
}

@supports (display: grid) {
  .presenterPupils {
    margin: auto;
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(188px, 188px));
  }
  .presenterPupilsItem {
    margin: 0 auto 0 0;
    width: auto;
  }
}

.viewerLinkWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
}

.viewerLinkIcon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -3px;
  transform: translateY(-50%);
}

.viewerLink {
  font-size: 16px;
  line-height: 30px;
  color: #3381dc;
  border: none;
  width: 270px;
  height: 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
  background: transparent;
  cursor: pointer;
}

.pupilsList {
  padding: 0;
  margin: 0 -10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 300px;
}

.sticky {
  position: sticky;
  top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@supports (display: grid) {
  .pupilsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  }
  .pupilsListItem {
    margin: 0 auto 0 0;
    width: auto;
    max-width: 100%;
  }
}

.groupButton {
  width: 100%;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  background: #4da3f7;
  border-radius: 6px;
  height: 45px;
  margin: 30px 0 20px;
  max-width: 280px;
  border: none;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1em;
}

.switchWrap {
  position: relative;
  margin: 20px 0;
}

.presenterWrap .group_1 {
  color: var(--color1);
}

.presenterWrap .group_2 {
  color: var(--color2);
}

.presenterWrap .group_3 {
  color: var(--color3);
}

.presenterWrap .group_4 {
  color: var(--color4);
}

.presenterWrap .group_5 {
  color: var(--color5);
}

.presenterWrap .group_6 {
  color: var(--color6);
}

.button {
  border-radius: 10px;
  outline: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 18px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-top: auto;
  cursor: pointer;
}

.button.authorizationBtn {
  background: var(--primary);
  width: 280px;
  margin: 0 0 20px 0;
  border: 2px solid var(--primary);
  transition: all 0.3s ease;
}

.button.authorizationBtn svg {
  stroke: #fff;
  transition: color 0.3s ease;
}

.button.authorizationBtn:hover {
  background: transparent;
  color: var(--primary);
}

.button.authorizationBtn:hover svg {
  stroke: var(--primary);
}

.button:hover {
  outline: none;
}

.startButton {
  flex-shrink: 0;
  background: var(--primary);
  border: 2px solid var(--primary);
  transition: all 0.3s ease;
}

.startButton svg {
  fill: #fff;
  transition: color 0.3s ease;
}

.startButton:hover {
  background: transparent;
  color: var(--primary);
}

.startButton:hover svg {
  fill: var(--primary);
}

.stopButton {
  flex-shrink: 0;
  background: var(--danger);
  border: 2px solid var(--danger);
  transition: all 0.3s ease;
}

.stopButton svg {
  fill: white;
  transition: fill 0.3s ease;
}

.stopButton:hover {
  background: transparent;
  color: var(--danger);
  & svg {
    fill: var(--danger);
  }
}

.marginRight10 {
  margin-right: 10px;
}

.groupTaskSlot {
  max-width: 280px;
  color: #393f61;
}

.groupTaskSlot_1 {
  color: var(--color1);
}

.groupTaskSlot_2 {
  color: var(--color2);
}

.groupTaskSlot_3 {
  color: var(--color3);
}

.groupTaskSlot_4 {
  color: var(--color4);
}

.groupTaskSlot_5 {
  color: var(--color5);
}

.groupTaskSlot_6 {
  color: var(--color6);
}

.viewToggle {
  display: flex;
  align-items: center;
  color: #393f61 !important;
}

.viewToggle span {
  margin-left: 5px;
}

.viewIcon {
  width: 25px;
  height: 25px;
  fill: #4da3f7 !important;
}

.viewLabel {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #4da3f7;
}

.feedbacklink {
  font-size: 14px;
}

.iframe {
  width: 100%;
  min-height: 400px;
}

.chatIcon {
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-image: url("./../ViewerScreen/img/chat_icon.svg");
  background-color: var(--color8);
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  bottom: 0.5em;
  cursor: pointer;
}

.chatIcon.isOpen,
.chatIcon:not(.isOpen):hover {
  background-color: var(--primary);
}

.tooltip {
  z-index: 99999999999999 !important;
  max-width: 170px !important;
  font-weight: normal;
  font-size: 14px;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  border-radius: 10px;
  display: flex !important;
  align-items: flex-end !important;
}

.emojiBlock {
  position: relative;
  margin-top: auto;
  box-sizing: border-box;
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);
  border-radius: 6px;
  width: 290px;
}

.emojiClose {
  position: absolute;
  top: 10px;
  right: 12px;
  height: 12px;
  cursor: pointer;
}

.emojiList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}

.emojiItem {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: end;
  flex-shrink: 0;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #393f61;
  & svg {
    margin-right: 5px;
    width: 32px;
    height: 24px;
  }
}

.emojiTitle {
  margin: 0;
  color: #393f61;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
}

.emojiOpenHelp {
  cursor: pointer;
  position: relative;
  margin-top: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  & svg {
    width: 32px;
    height: 24px;
    margin-right: 10px;
  }
  & p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #4da3f7;
    margin: 0;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.3s ease;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    right: 95px;
    top: 5px;
    width: 16px;
    height: 16px;
    background: url("./img/question.svg") center no-repeat;
    transition: opacity 0.3s ease;
  }
  &:after {
    opacity: 0;
    background: url("./img/questionFill.svg") center no-repeat;
  }
  &:hover {
    & p {
      border-bottom: 1px solid #4da3f7;
    }
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

.modeOptionBtn {
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &.selected {
    background: var(--primary);
    border-radius: 2px;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.buttons {
  z-index: 3;
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  box-sizing: border-box;
  flex-shrink: 0;
}
