.wrap
  position: fixed
  display: none

.open
  position: fixed
  overflow: auto
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: #00000034
  display: flex
  align-items: center
  justify-content: center
  z-index: 100


.inner
  width: 100%
  position: relative
  max-width: 660px
  padding: 50px 90px
  background: #fff
  box-sizing: border-box
  color: #393f61
  box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2)
  border-radius: 10px


.innerAuto
  width: auto
  max-width: 100%


.close
  z-index: 2
  cursor: pointer
  border: none
  padding: 0
  background: none
  margin: 0 0 0 auto
  position: absolute
  right: 20px
  top: 20px


.buttons
  margin: 0 -5px


.buttons button
  margin: 0 5px


.title
  text-align: center
  margin-top: 0
  margin-bottom: 40px

