.popupMenuMainContainer {
    /*height: 90%;*/
}

.popupMenuContentContainer {
    /*position: relative;*/
    height: 100%;
    box-sizing: border-box;
    margin-top: 77px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

