.wrap {
    position: relative;
    width: 86px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 86px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 30px;
    position: absolute;
    z-index: 99;
}

.innerContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    min-width: 27px;
}

.controlOfButtonDoneNext {
    display: inline-block;
}