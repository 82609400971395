.wrap {
    text-align: center;
    padding: 5px;
    margin: 1px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    border-radius: 2px;
    min-height: 115px;
    box-sizing: border-box;
}

.inner {
    position: relative;
    z-index: 2;
    color: #4da3f7;
}

.group, .group .inner {
    color: inherit;
}

.iconContainer {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 5px auto 0;

    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 50%;
    background: #fff;
}

.ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.icon {
    position: relative;
    z-index: 2;
}

.name {
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.timerValue {
    font-weight: bold;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    
}

.selected .inner {
    color: #fff;
}


.group:before, .selected:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.2;
    border-radius: 2px;
    transition: all 0.4s;
    background-color: currentColor;
}

.selected:before {
    opacity: 1;
    box-shadow: 0 0 currentColor 4px;
}

[class*="selected_"],
[class*="group_"] {
    color: var(--primary);
}

.selected_1,
.group_1 {
    color: var(--color1);
}

.selected_2,
.group_2 {
    color: var(--color2);
}

.selected_3,
.group_3 {
    color: var(--color3);
}

.selected_4,
.group_4 {
    color: var(--color4);
}

.selected_5,
.group_5 {
    color: var(--color5);
}

.selected_6,
.group_6 {
    color: var(--color6);
}

.danger .name,
.danger .timerValue {
    /* color: var(--danger); */
}
