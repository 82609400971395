.paymentContainer {
    max-width: 450px;
    margin: 40px auto;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 15px 40px rgb(166 207 228);
}

.paymentTitle {
    text-align: center;
    font-size: 36px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
}

.paymentDetailTitle {
    margin-bottom: 30px;
}

.paymentLinks {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.paymentLink {
    color: #f18825;
    text-decoration: none;
}

.paymentLink:hover {
    text-decoration: underline;
}