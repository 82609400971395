.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #393f61;
    font-family: "Circe";
    overflow-x: hidden;
}



.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
    font-family: "Circe";
}

.main {
    flex-grow: 2;
    background: #e5f6ff;
    border-radius: 0 0 10px 10px;
    padding-bottom: 54px;
    color: #393F61;
}

.footer {
    overflow: hidden;
    text-align: center;
    background: #393f61;
    color: #fff;
    padding: 38px 0;
    flex-shrink: 0;
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.section {
    max-width: 1090px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    /* overflow: hidden; */
}

.footerTop {
    margin-bottom: 22px;
}

.tel {
    margin-top: 15px;
    display: inline-block;
}

.content {
    margin-top: 73px;
}

.hello {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    text-align: center;

    color: #393f61;
}



.footerFirstText,
.footerFirstPhone {
    
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 187% */

    text-align: center;

    color: #ffffff;

    opacity: 0.6;
}

.timerWrap {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    text-align: center;

    color: rgba(0, 0, 0, 0.4);

    background: #ffffff;
    border-radius: 16px;
    max-width: 320px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    margin: 44px auto;
}

.timerTop {
    margin-bottom: 18px;
}

.timer {
    text-align: center;
    white-space: nowrap;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.timerItem {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 0 10px;
}

.timerValue {
    font-family: 'Circe';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 1em;
    /* identical to box height, or 30px */

    text-align: center;
    letter-spacing: 0.1em;

    /* #252A2C */

    color: #393f61;
    position: relative;
    min-width: 40px;
}

.timerValue:after {
    content: ":";
    position: absolute;
    display: block;
    left: 100%;
    top: 0%;
    transform: translate(50%, -4px);
}

.timerItem:last-child .timerValue::after {
    content: none;
}

.footerLinks {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.footerLinks a {
    margin-left: 20px;
}
