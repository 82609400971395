.loaderWrapper {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    text-align: center;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}