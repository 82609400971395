.page {
  height: 100%;
}

.blueText {
  color: var(--primary)
}

.plainText {
  font-weight: 400;
}

.boldText {
  font-weight: 800;
}

.errorText {
  color: var(--danger);
}

.icon {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.bTestStatus{
  display: flex;
  align-items: center;
  padding: 13px 45px;
  margin: 0 0 10px;
  color: white;
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  box-shadow: 0 2px 10px rgba(17, 48, 127, 0.2);

  &.pending {
    background-color: #666;
    & .icon {
      background-image: url("img/warn.svg");
    }
  }

  &.failed {
    background-color: var(--danger);
    & .icon {
      background-image: url("img/warn.svg");
    }
  }

  &.succeed {
    background-color: var(--succeed);
    & .icon {
      background-image: url("img/done.svg");
    }
  }
}

.content {
  margin: 30px 60px 0 80px;
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--color8);
  width: calc(50% - 160px);
  min-width: 580px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;

  h1 {
    font-family: Montserrat;
    font-weight: 800;
    font-size: 50px;
    line-height: 64px;
    margin: 30px 0 40px
  }

  .versionsBlock, .musicBlock {
    margin-bottom: 40px;
  }

  .videoInfo {
    margin-bottom: 20px;
  }
}

.comment {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-end;

  &Block {
    margin-bottom: 17px;
    width: 100%;

    &::after {
      position: relative;
      content: '';
      height: 0;
      width: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-top: 14px solid var(--blue-background);
      display: block;
      left: calc(100%/2 - 13px);
    }
  }

  &Text{
    background-color: var(--blue-background);
    border-radius: 10px;
    padding: 40px;

    .icon {
      background-image: url("img/screen.svg");
      bottom: -6px;
    }
  }

  &Fox{
    align-self: center;
    img {
      margin-bottom:-7px;
    }
  }
}

.bVideoTest {
  display: flex;
  margin: 40px 0;

  .localVideo {
    max-width: 280px;
    height: 170px;
    margin: 0 20px 20px 0;
  }

  .videoTitle {
    display: flex;
    align-items: center;
    color: var(--danger);

    .icon {
      background-image: url("img/error.svg")
    }

    &.success {
      color: var(--color8);

      .icon {
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-image: url("img/done.svg");
        background-color: var(--color8);
        background-image: none;
      }
    }
  }
}

@media screen and (max-width: 992px){
  .content {
    width: calc(100% - 140px);
  }
}

@media screen and (max-width:600px) {
  .bTestStatus {
    height: 26px;
    padding: 12px 10px;
    line-height: 25px;
  }

  .content {
    width: calc(100% - 20px);
    margin: 10px 10px 0;
    min-width: auto;
    h1 {
      font-size: 36px;
      margin: 0;
    }
  }

  .comment {
    &Block {
      margin-bottom: 10px;
    }
    &Text{
      background-color: #EDF6FF;
      border-radius: 10px;
      padding: 17px 9px 5px 11px;
    }
  }

  .bVideoTest {
    display: flex;
    margin: 20px 0 40px;

    .localVideo {
      max-width: 172px;
      height: 103px;
    }
  }
}

.videoPlaceholder {
  video {
    background-image: url("/images/test/video.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.hide {
    display: none;
  }
}

.versionsBlock {
  margin-bottom: 20px;
}

.musicBlock {
  margin-bottom: 20px;
}

.musicButton {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  .icon {
    background-image: url("img/micro.svg");
    margin-left: 10px;
  }
}